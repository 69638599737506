import React, { useEffect, useRef, useState } from 'react';
import { AutoComplete, Input, InputNumber } from 'antd';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import ClickAwayListener from 'react-click-away-listener';
import MobileKeyboard from './MobileKeyboard';
import useUserData from '../hooks/useUserData';

const KeyboardContainer = styled.div`    
  position: fixed;
  width: 100%;
  height: 200px;
  bottom: 0;
  left: 0;
  padding: 5px;
  z-index: 1000;
  background-color: var(--primaryBgColor);
`;

export function BottomKeyboard({
  keyboardRef, onClickAway, onChange, layoutName = 'default', ...props
}) {
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <KeyboardContainer>
        <MobileKeyboard
          keyboardRef={keyboardRef}
          onChange={onChange}
          layoutName={layoutName}
          {...props}
        />
      </KeyboardContainer>
    </ClickAwayListener>
  );
}

export default function InputWithKeyboard({ onChange, ...props }) {
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const keyboard = useRef(null);
  const inputRef = useRef(null);
  const { usesExternalReader } = useUserData();

  useEffect(() => {
    if (isMobile && usesExternalReader && inputRef.current !== null) {
      inputRef.current.readOnly = true;
    }
  }, [isMobile, usesExternalReader, keyboard]);

  const onChangeKeyboardValue = (value) => {
    setInputValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  const onChangeInputValue = (value) => {
    setInputValue(value);
    if (keyboard.current) {
      keyboard.current.setInput(value);
    }
    if (onChange) {
      onChange(value);
    }
  };

  const onClickAway = () => {
    if (inputRef.current !== document.activeElement) {
      setShowKeyboard(false);
    }
  };

  return (
    <>
      <Input
        value={inputValue}
        onChange={onChangeInputValue}
        onFocus={() => setShowKeyboard(true)}
        ref={inputRef}
        {...props}
      />

      {isMobile && usesExternalReader && showKeyboard && (
        <BottomKeyboard
          keyboardRef={(ref) => {
            keyboard.current = ref;
            keyboard.current.replaceInput({
              default: inputValue,
            });
          }}
          onClickAway={onClickAway}
          onChange={onChangeKeyboardValue}
        />
      )}
    </>
  );
}

export function InputNumberWithKeyboard({ onChange, ...props }) {
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const keyboard = useRef(null);
  const numberInput = useRef(null);
  const { usesExternalReader } = useUserData();

  useEffect(() => {
    if (isMobile && usesExternalReader && numberInput.current !== null) {
      numberInput.current.readOnly = true;
    }
  }, [isMobile, usesExternalReader, keyboard]);

  const onChangeKeyboardValue = (value) => {
    setInputValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  const onChangeInputValue = (value) => {
    setInputValue(value);
    if (keyboard.current) {
      keyboard.current.setInput(value);
    }
    if (onChange) {
      onChange(value);
    }
  };

  const onClickAway = () => {
    if (numberInput.current !== document.activeElement) {
      setShowKeyboard(false);
    }
  };

  return (
    <>
      <InputNumber
        value={inputValue}
        onChange={onChangeInputValue}
        onFocus={() => setShowKeyboard(true)}
        ref={numberInput}
        {...props}
      />

      {isMobile && usesExternalReader && showKeyboard && (
        <BottomKeyboard
          keyboardRef={(ref) => {
            keyboard.current = ref;
            keyboard.current.replaceInput({
              default: inputValue,
            });
          }}
          onClickAway={onClickAway}
          onChange={onChangeKeyboardValue}
          layoutName="numbersOnly"
        />
      )}
    </>
  );
}

export function InputSearchWithKeyboard({ onChange, onSearch, ...props }) {
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const keyboard = useRef(null);
  const searchInput = useRef(null);
  const { usesExternalReader } = useUserData();

  useEffect(() => {
    if (isMobile && usesExternalReader && searchInput.current !== null) {
      searchInput.current.input.readOnly = true;
    }
  }, [isMobile, usesExternalReader, keyboard]);

  const onChangeKeyboardValue = (value) => {
    setInputValue(value);
    if (onSearch) {
      onSearch(value);
    }
  };

  const onChangeInputValue = (event) => {
    const { value } = event.target;
    setInputValue(event.target.value);
    if (keyboard.current) {
      keyboard.current.setInput(value);
    }
    if (onChange) {
      onChange(event);
    }
    if (onSearch) {
      onSearch(value);
    }
  };

  const onClickAway = () => {
    if (searchInput.current !== document.activeElement) {
      setShowKeyboard(false);
    }
  };

  return (
    <>
      <Input.Search
        value={inputValue}
        onSearch={onSearch}
        onChange={onChangeInputValue}
        onFocus={() => setShowKeyboard(true)}
        ref={searchInput}
        {...props}
      />

      {isMobile && usesExternalReader && showKeyboard && (
        <BottomKeyboard
          keyboardRef={(ref) => {
            keyboard.current = ref;
            keyboard.current.replaceInput({
              default: inputValue,
            });
          }}
          onClickAway={onClickAway}
          onChange={onChangeKeyboardValue}
        />
      )}
    </>
  );
}

export function AutoCompleteWithKeyboard({
  onChange, onSearch, onSelect, children, ...props
}) {
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const autocomplete = useRef(null);
  const keyboard = useRef(null);
  const { usesExternalReader } = useUserData();

  useEffect(() => {
    if (isMobile && usesExternalReader && autocomplete.current !== null) {
      const inputRef = autocomplete.current.props.getInputElement().ref;
      inputRef.current.input.readOnly = true;
    }
  }, [isMobile, usesExternalReader, keyboard]);

  const onChangeKeyboardValue = (value) => {
    setSearchValue(value);
    if (onChange) {
      onChange(value);
    }
    if (onSearch) {
      onSearch(value);
    }
  };

  const onChangeInputValue = (value) => {
    setSearchValue(value);
    if (keyboard.current) {
      keyboard.current.setInput(value);
    }
    if (onChange) {
      onChange(value);
    }
  };

  const onSelectValue = (value, option) => {
    setShowKeyboard(false);
    setDropdownOpen(false);
    if (onSelect) {
      onSelect(value, option);
    }
  };

  const onFocus = () => {
    setShowKeyboard(true);
    setDropdownOpen(true);
  };

  const onClickAway = () => {
    if (autocomplete.current !== document.activeElement) {
      setShowKeyboard(false);
    }
  };

  return (
    <>
      <AutoComplete
        value={searchValue}
        listHeight={isMobile ? 100 : undefined}
        onSearch={onSearch}
        onChange={onChangeInputValue}
        onSelect={onSelectValue}
        onFocus={onFocus}
        open={dropdownOpen}
        ref={autocomplete}
        {...props}
      >
        {children}
      </AutoComplete>

      {isMobile && usesExternalReader && showKeyboard && (
        <BottomKeyboard
          keyboardRef={(ref) => {
            keyboard.current = ref;
            keyboard.current.replaceInput({
              default: searchValue,
            });
          }}
          onClickAway={onClickAway}
          onChange={onChangeKeyboardValue}
        />
      )}
    </>
  );
}
