import React from 'react';
import Webcam from 'react-webcam';
import { Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

const WebcamCapture = () => {
  const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState(null);
  const videoConstraints = { facingMode: 'environment' };

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    SelectedElementFoto(imageSrc);
  }, [webcamRef, setImgSrc]);

  return (
    <div style={{ maxWidth: '320px' }}>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
      />
      <Button onClick={capture} type="primary" style={{ display: 'block', margin: '0 auto' }} shape="circle" icon={<PlusCircleOutlined />} size="large" />
    </div>
  );
};

export default WebcamCapture;

const SelectedElementFoto = (datauri) => {
  const elemaux = JSON.parse(window.StepsData);
  let elements = [];
  if (typeof elemaux === 'string') {
    elements = JSON.parse(elemaux);
  } else {
    elements = elemaux;
  }

  if (elements.length > 0) {
    let fotos = 0;
    elements.forEach((item) => {
      if (item.tipo === 2 && document.getElementById(`img_${item.id.toString()}`).getAttribute('carga') === 'false' && fotos === 0) {
        fotos += 1;
        document.getElementById(`tagfotored_${item.id.toString()}`).style.display = 'none';
        document.getElementById(`tagfotogreen_${item.id.toString()}`).style.display = 'block';
        /* document.getElementById("foto_"+item.id.toString()).style.color = "green"; */
        document.getElementById(`img_${item.id.toString()}`).setAttribute('carga', 'true');
        document.getElementById(`img_${item.id.toString()}`).getElementsByClassName('ant-image-img')[0].setAttribute('src', datauri);
        document.getElementById(`checkFoto_${item.id.toString()}`).setAttribute('value', 'true');
      }
    });
  }
};
