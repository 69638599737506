import React, { useState } from 'react';
import Keyboard from 'react-simple-keyboard';

export default function MobileKeyboard({ layoutName, onKeyPress, ...props }) {
  const [currentLayoutName, setCurrentLayoutName] = useState(layoutName);

  function mobileOnKeyPress(button) {
    if (button === '{shift}' || button === '{lock}') handleShift();
    if (button === '{numbers}') setCurrentLayoutName('numbers');
    if (button === '{symbols}') setCurrentLayoutName('symbols');
    if (button === '{abc}') setCurrentLayoutName('default');
    if (onKeyPress) {
      onKeyPress(button);
    }
  }

  function handleShift() {
    setCurrentLayoutName(currentLayoutName === 'default' ? 'shift' : 'default');
  }

  return (
    <Keyboard
      layoutName={currentLayoutName}
      mergeDisplay
      layout={{
        default: [
          'q w e r t y u i o p',
          'a s d f g h j k l ñ',
          '{shift} z x c v b n m {backspace}',
          '{numbers} {space} {ent}',
        ],
        shift: [
          'Q W E R T Y U I O P',
          'A S D F G H J K L Ñ',
          '{shift} Z X C V B N M {backspace}',
          '{numbers} {space} {ent}',
        ],
        numbers: [
          '1 2 3 4 5 6 7 8 9 0',
          '- / : ; ( ) $ & @ "',
          '{symbols} . , ? ! \' {backspace}',
          '{abc} {space} {ent}',
        ],
        symbols: [
          '[ ] { } # % ^ * + =',
          '_ \\ | ~ < > € £ ¥ ·',
          '{numbers} . , ? ! \' {backspace}',
          '{abc} {backspace} {ent}',
        ],
        numbersOnly: ['1 2 3', '4 5 6', '7 8 9', '[] 0 {backspace}'],
      }}
      display={{
        '{numbers}': '123',
        '{symbols}': '#+=',
        '{ent}': 'intro',
        '{escape}': 'esc ⎋',
        '{tab}': 'tab ⇥',
        '{backspace}': '⌫',
        '{capslock}': 'caps lock ⇪',
        '{shift}': '⇧',
        '{controlleft}': 'ctrl ⌃',
        '{controlright}': 'ctrl ⌃',
        '{altleft}': 'alt ⌥',
        '{altright}': 'alt ⌥',
        '{metaleft}': 'cmd ⌘',
        '{metaright}': 'cmd ⌘',
        '{abc}': 'ABC',
        '{space}': 'espacio',
      }}
      onKeyPress={mobileOnKeyPress}
      {...props}
    />
  );
}
