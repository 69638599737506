import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import SpanButton from '../../components/SpanButton';

export default function ViewModal(props) {
  const {
    textBtn, titleModal, typeBtn, styleBtn, id, body,
  } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const typeButton = () => {
    if (typeBtn === 'button') {
      return (
        <Button type="button" id={`btn${id}`} style={styleBtn || {}} onClick={showModal}>
          {textBtn}
        </Button>
      );
    }
    return (
      <SpanButton
        key="show-modal-link"
        id={`lnk${id}`}
        style={styleBtn || {}}
        onClick={showModal}
      >
        {textBtn}
      </SpanButton>
    );
  };

  const closeButton = () => (
    <Button type="button" id={`btn_hidden_${id}`} style={{ display: 'none' }} onClick={handleCancel}>
      cerrar modal
    </Button>
  );

  return (
    <>
      {typeButton()}
      {closeButton()}
      <Modal
        destroyOnClose
        key={`mdl${id}`}
        title={titleModal}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        { body }
      </Modal>
    </>
  );
}
