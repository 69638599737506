import React, { Component, useEffect, useState } from 'react';
import QrReader from 'react-qr-reader';
import useSound from 'use-sound';
import {
  Alert, Button, Image, message,
} from 'antd';
import { isIOS } from 'react-device-detect';
import Search from 'antd/es/input/Search';
import boopSfx from '../../../assets/sounds/boop.mp3';
import { authAxios } from '../../../utils/axios-instance';
import scannQr from '../../../assets/images/scanner_qr.svg';

const LeerQr = () => {
  const [result, setResult] = useState('No result');
  const [camara, setCamara] = useState('qr');
  const [viewCam, setViewCam] = useState('');
  const [urlScan, setUrlScan] = useState('');
  const [viewManual, setViewManual] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorDataMsg, setErrorDataMsg] = useState(false);

  const addEvent = (element, eventName, callback) => {
    if (element.addEventListener) {
      element.addEventListener(eventName, callback, false);
    } else if (element.attachEvent) {
      element.attachEvent(`on${eventName}`, callback);
    } else {
      element[`on${eventName}`] = callback;
    }
  };

  const handleScan = (data) => {
    if (data) {
      setResult(data);
      setLoading(true);
      agregarLoadingExt();
      SelectedElementQR(data);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const mostrarInput = () => {
    if (document.getElementById('parrafoManual').style.display === 'none') {
      document.getElementById('parrafoManual').style.display = 'block';
    } else {
      document.getElementById('parrafoManual').style.display = 'none';
    }
    document.getElementById('inputManual').setAttribute('value', '');
  };

  const addEvento = () => {
    let magic_input = '';
    let timeout_magic_input = null;
    addEvent(document, 'keydown', (e) => {
      e = e || window.event;
      if (magic_input === 'http') {
        agregarLoadingExt();
      }
      if (magic_input !== undefined) {
        clearTimeout(timeout_magic_input);
        timeout_magic_input = setTimeout(() => {
          magic_input = '';
        }, 700);
        if (e.key.toString().toLowerCase() !== 'shift' && e.key.toString().toLowerCase() !== 'enter' && !magic_input.includes('scan_element')) {
          magic_input += e.key;
        }
        if (magic_input.includes('_url_elemento_')) {
          const exist = document.getElementById('img_esc_ext_ent');
          if (exist !== null) {
            SelectedElementQR(magic_input);
          } else {
            message.error('Hay errores con el escaner');
          }
        }
      }
    });
  };

  useEffect(() => {
    const usData = JSON.parse(localStorage.getItem('userData'));
    let lector_ex = true;
    if (usData.config_user && usData.config_user.propiedades) {
      lector_ex = usData.config_user.propiedades.lector_externo;
    }

    if (!lector_ex && !isIOS) {
      setViewCam(
        <div style={{ maxWidth: '320px' }}>
          <p>
            <QrReader
              delay={300}
              onError={handleError}
              onScan={handleScan}
              style={{ width: '100%' }}
            />
          </p>
        </div>,
      );
    } else {
      addEvento();
      setViewCam(
        <>
          <div>
            <Image
              id="img_esc_ext_ent"
              style={{
                display: 'block', margin: '0 auto', maxWidth: '160px', maxHeight: '200px',
              }}
              src={scannQr}
              preview={false}
            />
            <p>
              {isIOS ? (
                <Alert message="La cámara de su dispositivo no es compatible, use un lector externo" type="error" showIcon />
              ) : ''}
            </p>
          </div>
        </>,
      );
    }

    setViewManual(
      <div style={{ marginTop: '30px' }}>
        <p><Button type="button" onClick={mostrarInput}>Ingresar Código manual</Button></p>
        <p id="parrafoManual" style={{ display: 'none' }}><Search id="inputManual" autocomplete="off" style={{ width: '100%' }} placeholder="Escriba código" onSearch={onSearch} /></p>
      </div>,
    );
  }, []);

  return (
    <div>
      <BoopButton />
      {viewCam}
      {viewManual}
    </div>
  );
};

export default LeerQr;

const BoopButton = () => {
  const [play] = useSound(boopSfx);
  return (
    <button id="boton-sonido" type="button" style={{ display: 'none' }} onClick={play}>
      Boop!
    </button>
  );
};

const agregarLoadingExt = () => {
  if (!document.contains(document.getElementById('container_loader'))) {
    const elemDiv = document.createElement('div');
    elemDiv.id = 'container_loader';
    elemDiv.innerHTML = "<div class=\"loader\"></div><br><div><p style='text-align: center'>Escaneando...</p></div>";
    document.body.appendChild(elemDiv);
  }
};

const eliminarLoadingExt = () => {
  if (document.contains(document.getElementById('container_loader'))) {
    document.getElementById('container_loader').remove();
  }
};

const SelectedElementQR = (datascan) => {
  if (datascan) {
    const elements = JSON.parse(window.StepsData);
    if (datascan.includes('_url_elemento_')) {
      const url = `${process.env.REACT_APP_API_URL}${atob(datascan.replace('http', '').replace('_url_elemento_', ''))}`;
      authAxios.get(`${url}`)
        .then((response) => {
          document.getElementById('boton-sonido').click();
          if (elements.length > 0) {
            let elementoselect = '';
            let elementoselectcodigo = '';
            let elementotiposelect = '';
            let elementotipo = '';
            elements.forEach((item) => {
              if (item.id === response.data.id) {
                elementoselect = response.data.id.toString();
                elementoselectcodigo = response.data.codigo;
                elementotiposelect = response.data.tipo_nombre;
                elementotipo = response.data.tipo;
              }
            });
            if (elementoselect !== '') {
              let yaseleccionado = false;
              let elementoenfoco = false;
              const els = document.getElementsByClassName('lista_elem');
              const first = els[0];
              const inputs = first.getElementsByTagName('input');
              const spans = first.getElementsByTagName('span');
              const d_index = first.getAttribute('data-index');
              for (let i = 0, n = inputs.length; i < n; i += 1) {
                const el = inputs[i];
                if (el.id === `checkId_${elementoselect}`) {
                  el.setAttribute('d-index', d_index);
                }
                if (el.id === `checkScan_${elementoselect}`) {
                  elementoenfoco = true;
                  el.setAttribute('name', `checkScan_${d_index}`);
                  if (el.getAttribute('value') === 'true') {
                    yaseleccionado = true;
                  }
                  el.setAttribute('value', 'true');
                }

                if (elementotipo === 2) {
                  if (el.id === `checkNump_${elementoselect}`) {
                    el.setAttribute('name', `checkNump_${d_index}`);
                  }
                  if (el.id === `checkFoto_${elementoselect}`) {
                    el.setAttribute('name', `checkFoto_${d_index}`);
                  }
                }
              }
              for (let i = 0, n = spans.length; i < n; i += 1) {
                const el = spans[i];
                if (el.id === `tagqrred_${elementoselect}`) {
                  el.style.display = 'none';
                }
                if (el.id === `tagqrgreen_${elementoselect}`) {
                  el.style.display = 'block';
                }
              }

              if (elementoenfoco) {
                if (!yaseleccionado) {
                  message.success(`${elementotiposelect} ${elementoselectcodigo} escaneado`);
                }
              } else {
                message.error(`${elementotiposelect} ${elementoselectcodigo} no seleccionado`);
              }
            } else {
              message.error('El elemento escaneado no es de este usuario');
            }
          }
          eliminarLoadingExt();
        }).catch((response) => {
          console.log(response);
          eliminarLoadingExt();
          console.log(response.message);
        });
    } else {
      message.error('Error al escanear el elemento');
      eliminarLoadingExt();
    }
  }
};

const onSearch = (value) => {
  const arrayTipoElement = ['', 'CA', 'BO', 'CM', 'CE', 'BA'];
  const abrevTipo = value.slice(0, 2);
  const codeElem = value.slice(2, value.length);

  if (value.trim() !== '' && Number(codeElem) > 0) {
    const elements = JSON.parse(window.StepsData);
    if (elements.length > 0) {
      let encontrado = false;
      elements.forEach((item) => {
        if (
          item.id === Number(codeElem)
          && arrayTipoElement[item.tipo].toLowerCase() === abrevTipo.toLowerCase()
        ) {
          encontrado = true;
        }
      });
      if (encontrado === true) {
        const search = `http_url_elemento_${btoa(`/api/elementos/${value.toUpperCase()}/scan_element/`)}`;
        SelectedElementQR(search);
      } else {
        message.error('Código inválido');
      }
    }
  } else {
    message.error('Código inválido');
    eliminarLoadingExt();
  }
};
