import React, { useEffect } from 'react';
import {
  BrowserRouter, Route, Switch, NavLink,
} from 'react-router-dom';
import Layout, { Content, Header } from 'antd/es/layout/layout';
import Login from './pages/Login';

import useToken from './hooks/useToken';
import useUserData, { fetchUserData } from './hooks/useUserData';
import './styles/App.css';
import 'antd/dist/antd.css';
import Menu from './pages/Menu';
import MenuRight from './components/menu/MenuRight';
import MenuMobile from './components/menu/MenuMobile';

import Lockers from './pages/Lockers';
import Home from './pages/Home';
import Elementos from './pages/Elementos';
import Mantenedores from './pages/Mantenedores';
import Movimientos from './pages/Movimientos';
import Cuenta from './pages/Cuenta';
import logout from './utils/logout';
import Reportes from './pages/Reportes';
import AboutUs from './pages/AboutUs';
import { authAxios, noAuthAxios } from './utils/axios-instance';

const App = () => {
  const { token, setToken } = useToken();
  const { userData, setUserData } = useUserData();

  authAxios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401 && token.refresh_token) {
        const { refresh_token: refresh } = token;
        if (refresh) {
          const data = new FormData();
          data.append('grant_type', 'refresh_token');
          data.append('refresh_token', refresh);
          data.append('client_id', process.env.REACT_APP_CLIENT_ID);
          return noAuthAxios
            .post(
              `${process.env.REACT_APP_API_URL}/o/token/`,
              data,
            )
            .then((response) => {
              const newToken = response.data;
              const { access_token: access, token_type: tokenType } = newToken;
              // eslint-disable-next-line
              error.response.config.headers.Authorization = `${tokenType} ${access}`;
              authAxios.defaults.headers.common.Authorization = `${tokenType} ${access}`;
              setToken(newToken);
              return authAxios(error.config);
            })
            .catch((err) => {
              logout();
              return Promise.reject(err);
            });
        }
        logout();
      }
      return Promise.reject(error);
    },
  );

  useEffect(() => {
    if (token) {
      const { access_token: access, token_type: tokenType } = token;
      // Update headers
      authAxios.defaults.headers.common.Authorization = `${tokenType} ${access}`;
      // Update user permissions
      fetchUserData().then((newUserData) => setUserData(newUserData));
    }
  }, [token]);

  if (!token || !userData) {
    return <Login setToken={setToken} />;
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Layout className="layout">
          <Cabecera />
          <Content>
            <div className="site-layout-content"><Main /></div>
          </Content>
          {/* <Footer style={{ textAlign: 'center' }}>Neosoft-Chile ©2021</Footer> */}
        </Layout>
      </BrowserRouter>
    </div>
  );
};

const Main = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/movimientos" component={Movimientos} />
    <Route exact path="/lockers" component={Lockers} />
    <Route exact path="/elementos" component={Elementos} />
    <Route exact path="/mantenedores" component={Mantenedores} />
    <Route exact path="/cuenta" component={Cuenta} />
    <Route exact path="/reportes" component={Reportes} />
    <Route exact path="/aboutus" component={AboutUs} />
  </Switch>
);

const Cabecera = () => {
  if (window.innerWidth > 768) {
    return (
      <Header>
        <NavLink to="/"><div className="logo" /></NavLink>
        <Menu />
        <div className="perfil" style={{ width: '76px' }}>
          <MenuRight />
        </div>
      </Header>
    );
  }
  return (
    <Header>
      <div style={{ width: '76px' }}>
        <MenuMobile />
      </div>
      <NavLink to="/"><div className="logo" /></NavLink>
      <div className="perfil" style={{ width: '76px' }}>
        <MenuRight />
      </div>
    </Header>
  );
};

export default App;
