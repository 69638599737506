import React, { useState } from 'react';
import { Row, Col, Radio } from 'antd';
import StepsEntregar from '../components/home/StepsEntregar';
import StepsRecibir from '../components/home/StepsRecibir';

const Home = () => {
  const [valor, setValor] = useState();

  const vistaHome = () => {
    switch (valor) {
      case 'entregar': return <StepsEntregar />;
      case 'recibir': return <StepsRecibir />;
      default: return <StepsEntregar />;
    }
  };

  return (
    <>
      <div className="home">
        <Row>
          <h1>Registro de Uso de Lockers</h1>
        </Row>
        <Row type="flex">
          <Col span={24}>
            <Radio.Group value={valor} onChange={(e) => setValor(e.target.value)}>
              <Radio.Button value="entregar">Entregar</Radio.Button>
              <Radio.Button value="recibir">Recibir</Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
        {vistaHome()}
      </div>
    </>
  );
};

export default Home;
