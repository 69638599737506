import React, { useEffect, useState } from 'react';
import {
  Alert,
  AutoComplete,
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  List,
  message,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import { authAxios } from '../utils/axios-instance';
import ViewModal from '../utils/common/ViewModal';
import SpanButton from '../components/SpanButton';
import { InputSearchWithKeyboard } from '../components/InputWithKeyboard';
import { defaultPagination, defaultParams, handleTableChange } from '../utils/tables';

const { Option } = Select;

function loadingSocioData(
  params = {},
  processResult,
  errorResult,
) {
  authAxios.get(`${process.env.REACT_APP_API_URL}/api/socios/`,
    {
      params: {
        ...params,
      },
    })
    .then((response) => {
      processResult(response);
    }).catch(() => {
      errorResult();
    });
}

const loadingElementData = (
  params = {},
  processResult,
  errorResult,
) => {
  authAxios.get(`${process.env.REACT_APP_API_URL}/api/elementos/`,
    {
      params: {
        ...params,
      },
    })
    .then((response) => {
      processResult(response);
    }).catch(() => {
      errorResult();
    });
};

const ElementoForm = ({
  record = null, onFinish, setShowForm, showForm, setRecord,
}) => {
  const [loading, setLoading] = useState(false);
  const [searchLoadingCasilla, setSearchLoadingCasilla] = useState(false);
  const [searchLoadingSocio, setSearchLoadingSocio] = useState(false);
  const [form] = Form.useForm();
  const [duplicatedCodigo, setDuplicatedCodigo] = useState('');
  const [selectedTipo, setSelectedTipo] = useState();
  const [casillas, setCasillas] = useState();
  const [selectedCasilla, setSelectedCasilla] = useState({});
  const [socios, setSocios] = useState([]);
  const [checked, setChecked] = useState('checked');
  const [selectedSocio, setSelectedSocio] = useState({});
  const listaTipo = [
    { label: 'Casilla', value: 1 },
    { label: 'Bolso', value: 2 },
    { label: 'Carro de golf manual', value: 3 },
    { label: 'Carro de golf eléctrico', value: 4 },
    { label: 'Batería', value: 5 },
  ];
  /*  Cuando se crea una nueva casilla (nuevo elemento)
    esta no puede tener el codigo de otro elemento. */
  const isDuplicate = (event) => {
    if (event.target.value.trim() !== '') {
      loadingElementData(
        {
          codigo__iexact: event.target.value,
        },
        (response) => {
          if (response.data.length > 0 && (!record || record.codigo !== response.data[0].codigo)) {
            setDuplicatedCodigo('duplicated');
          } else {
            setDuplicatedCodigo('valid');
          }
        },
        () => message.error('Error al solicitar información'),
      );
    }
  };
  const onChangeTipo = (option) => {
    if (option === 1) {
      setSelectedCasilla({});
      form.setFieldsValue({
        casilla: null,
      });
      form.setFieldsValue({
        num_palos: null,
      });
    } else if (option !== 2) {
      form.setFieldsValue({
        num_palos: null,
      });
    } else if (option === 2 && record && record.propiedades) {
      form.setFieldsValue({
        num_palos: record.propiedades.num_palos,
      });
    }
    setSelectedTipo(option);
  };
  /*  Funcion para buscar casillas donde su "codigo" incluya "searchText"
  */
  const onSearchCasilla = (searchText) => {
    setSelectedCasilla({});
    if (searchText) {
      setSearchLoadingCasilla(true);
      loadingElementData(
        {
          tipo: 1,
          codigo__istartswith: searchText,
          limit: 10,
          estado: true,
        },
        (response) => {
          setCasillas(response.data.results.map((casilla) => (
            {
              id: casilla.id,
              value: casilla.codigo,
              tipos_permitidos: casilla.tipos_permitidos,
            }
          )));
          setSearchLoadingCasilla(false);
        },
        () => {
          setSearchLoadingCasilla(false);
          message.error('Error al solicitar información');
        },
      );
    }
  };

  /* función para buscar socios con una request */
  const onSearchSocio = (searchText) => {
    setSelectedSocio({});
    setSearchLoadingSocio(true);
    loadingSocioData(
      {
        limit: 20,
        search: searchText,
        estado: true,
      },
      (response) => {
        setSocios(response.data.results.map((socio) => (
          {
            id: socio.id,
            value: `${socio.first_name} ${socio.last_name} (${socio.rut})`,
          }
        )));
        setSearchLoadingSocio(false);
      },
      () => {
        message.error('Error al solicitar información');
        setSearchLoadingSocio(false);
      },
    );
  };

  const onSubmitElemento = (values) => {
    form.validateFields(['casilla']);
    form
      .validateFields()
      .then(() => {
        setLoading(true);
        let tipos_permitidos = [];
        if (values.tipo === 1) {
          tipos_permitidos = !values.tipos_permitidos || values.tipos_permitidos.length === 0
            ? [2, 3, 4, 5]
            : values.tipos_permitidos;
        }
        if (values.id > 0) {
          const { num_palos, ...element_properties } = record.propiedades;
          authAxios.put(`${process.env.REACT_APP_API_URL}/api/elementos/${values.id}/`, {
            codigo_asignado: values.codigo,
            tipo: values.tipo,
            tipos_permitidos,
            estado: values.estado,
            contenedor: selectedCasilla.id || null,
            socio: selectedSocio.id || null,
            propiedades: {
              ...element_properties,
              ...(values.num_palos ? { num_palos: values.num_palos } : {}),
            },
          }).then(() => {
            message.success(`${values.codigo} editado`);
            setShowForm(false);
            setLoading(false);
            setRecord({});
            onFinish();
          }).catch(() => {
            message.error(`Error al editar el elemento ${values.codigo}`);
            setLoading(false);
          });
        } else {
          authAxios.post(`${process.env.REACT_APP_API_URL}/api/elementos/`, {
            codigo_asignado: (values.tipo === 1 ? values.codigo.toUpperCase() : values.codigo),
            tipo: values.tipo,
            tipos_permitidos,
            estado: values.estado,
            contenedor: selectedCasilla.id || null,
            socio: selectedSocio.id || null,
            propiedades: {
              ...(values.num_palos ? { num_palos: values.num_palos } : {}),
            },
          }).then((response) => {
            setShowForm(false);
            setLoading(false);
            setRecord({});
            message.success(`${response.data.tipo_nombre} - ${response.data.codigo} creado`);
            onFinish();
          }).catch(() => {
            message.error('Error al crear el elemento');
            setLoading(false);
          });
        }
      });
  };

  // inica el valor de selectedCasilla cuando se edita un elemento asociada a una casilla.
  useEffect(() => {
    setSelectedTipo(record ? record.tipo : 1);
    setSelectedSocio(
      record && record.socio
        ? {
          id: record.socio,
          value: `${record.first_name} ${record.last_name} (${record.rut})`,
        }
        : {},
    );
    setSelectedCasilla(
      record && record.contenedor
        ? {
          id: record.contenedor.id,
          value: record.contenedor.codigo,
          tipos_permitidos: record.tipos_permitidos,
        }
        : {},
    );
    setChecked(
      record && record.estado === false
        ? ''
        : 'checked',
    );
    if (record) {
      form.resetFields();
      form.setFieldsValue({
        id: record.id,
        codigo: record.codigo,
        tipo: record.tipo,
        estado: record.estado,
        num_palos: record.propiedades ? record.propiedades.num_palos : null,
        tipos_permitidos: record.tipos_permitidos.map((tipo) => tipo.id),
        casilla: record.contenedor ? record.contenedor.codigo : null,
        socio: record && record.socio
          ? `${record.first_name} ${record.last_name} (${record.rut})`
          : null,
      });
    } else {
      form.resetFields();
      form.setFieldsValue({
        id: 0,
        estado: true,
        tipo: 1,
        socio: null,
      });
    }
  }, []);

  useEffect(() => {
    if (duplicatedCodigo) {
      form.validateFields(['codigo']);
    }
  }, [duplicatedCodigo]);
  useEffect(() => {
    form.validateFields(['casilla']);
  }, [selectedCasilla, selectedTipo]);

  return (
    <>
      <Modal
        title={record ? 'Editar Elemento' : 'Crear Elemento'}
        visible={showForm}
        onCancel={() => {
          setShowForm(false);
          setRecord({});
        }}
        footer={null}
      >
        <Form
          form={form}
          name="complex-form"
          layout="vertical"
          wrapperCol={{ span: 16 }}
          onFinish={onSubmitElemento}
          autoComplete="off"
        >
          <Form.Item
            name="id"
            style={{ display: 'none' }}
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Estado"
            name="estado"
            rules={[{ required: false }]}
            valuePropName={checked}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label="Tipo"
            name="tipo"
            rules={[{ required: true, message: 'Tipo es requerido' }]}
          >
            <Select
              placeholder="Seleccione tipo"
              options={listaTipo}
              onChange={onChangeTipo}
            />
          </Form.Item>
          {selectedTipo === 1 && (
            <Form.Item
              label="Tipo elementos permitidos"
              name="tipos_permitidos"
              rules={[{ required: false },
                () => ({
                  validator() {
                    if (record) {
                      let isElementoAllowed = true;
                      record.elementos.forEach((elemento) => {
                        if (!form.getFieldValue('tipos_permitidos').includes(elemento.tipo)) {
                          isElementoAllowed = false;
                        }
                      });
                      if (!isElementoAllowed) {
                        return Promise.reject(new Error('Casilla contiene elementos no permitidos.'));
                      }
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Select
                mode="multiple"
                showArrow
                allowClear
                style={{ width: '100%' }}
                options={listaTipo.filter((tipo) => tipo.value !== 1)}
                onBlur={() => form.validateFields(['tipos_permitidos'])}
              />
            </Form.Item>
          )}
          <Form.Item
            label="Código"
            name="codigo"
            rules={[{ required: selectedTipo === 1 || record, message: 'Código es obligatorio' },
              { whitespace: true, message: 'Sin espacios en blanco al principio' },
              () => ({
                validator() {
                  if (duplicatedCodigo === 'duplicated') {
                    return Promise.reject(new Error('Codigo repetido'));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input onBlur={isDuplicate} />
          </Form.Item>
          {selectedTipo !== 1 && (
            <Form.Item
              label="Casilla"
              name="casilla"
              rules={[{ required: false },
                () => ({
                  validator() {
                    const tipoSelectedId = form.getFieldValue('tipo');
                    if (tipoSelectedId && selectedCasilla.tipos_permitidos
                      && selectedCasilla.tipos_permitidos.length > 0
                      && !selectedCasilla.tipos_permitidos
                        .find((tipo) => tipo.id === tipoSelectedId)) {
                      return Promise.reject(new Error('Elemento no permitido para la casilla'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <AutoComplete
                options={casillas}
                listHeight={200}
                onSearch={onSearchCasilla}
                onSelect={(selectedValue, option) => setSelectedCasilla(option)}
              >
                <Input.Search
                  size="large"
                  placeholder="Seleccione casilla"
                  loading={searchLoadingCasilla}
                />
              </AutoComplete>
            </Form.Item>
          )}
          <Form.Item
            label="Socio"
            name="socio"
            rules={[{ required: false }]}
          >
            <AutoComplete
              options={socios}
              onSearch={onSearchSocio}
              onSelect={(selectedValue, option) => setSelectedSocio(option)}
            >
              <Input.Search size="large" placeholder="Seleccione socio" loading={searchLoadingSocio} />
            </AutoComplete>
          </Form.Item>
          {selectedTipo === 2 && (
            <Form.Item
              label="Número de Palos"
              name="num_palos"
              rules={[{ required: false }]}
            >
              <InputNumber />
            </Form.Item>
          )}
          <Form.Item style={{ marginTop: 40 }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Guardar
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

const Mantenedores = () => {
  const [dataApi, setDataApi] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorDataMsg, setErrorDataMsg] = useState(false);
  const [textoBotonModal, setTextoBotonModal] = useState('Crear Socio');
  const [changeSelect, setChangeSelect] = useState('socios');
  const [pagination, setPagination] = useState(defaultPagination);
  const [fetchParams, setFetchParams] = useState(defaultParams);
  const [tableSearchText, setTableSearchText] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [elementoFormRecord, setElementoFormRecord] = useState({});

  const fetchSocioData = (newPagination, params = {}) => {
    loadingSocioData(
      params,
      (response) => {
        setPagination({
          ...newPagination,
          total: response.data.count,
        });
        setFetchParams(params);
        const dataSocios = [];
        const socios = response.data.results;
        socios.forEach((socio) => {
          let socioNombre = '';
          if (socio.first_name) {
            socioNombre = `${socio.first_name} ${socio.last_name}`;
          }
          dataSocios.push({
            key: socio.id,
            columna1: socioNombre,
            columna2: socio.rut || '',
            estado: socio.estado,
            datos: JSON.stringify(socio),
          });
        });
        setDataApi(dataSocios);
        setColumns(columnsSocio());
        setTextoBotonModal('Crear Socio');
        setLoading(false);
      },
      () => { setErrorDataMsg(true); },
    );
  };

  const fetchElementData = (newPagination, params = {}) => {
    loadingElementData(
      params,
      (response) => {
        setPagination({
          ...newPagination,
          total: response.data.count,
        });
        setFetchParams(params);
        const elementos = response.data.results;
        const dataElementos = [];
        elementos.forEach((elemento) => {
          const columna1 = `${elemento.tipo_nombre.charAt(0).toUpperCase() + elemento.tipo_nombre.slice(1)} - ${elemento.codigo}`;
          let socio = '';
          const columnaEditar = '';
          if (elemento.first_name) {
            socio = `${elemento.first_name} ${elemento.last_name}`;
          }
          dataElementos.push({
            key: elemento.id,
            columna1,
            columna2: socio,
            columnaEditar,
            ...elemento,
            datos: JSON.stringify(elemento),
          });
        });
        setDataApi(dataElementos);
        setColumns(columnsElemento());
        setTextoBotonModal('Crear Elemento');
        setLoading(false);
      },
      () => { setErrorDataMsg(true); },
    );
  };

  useEffect(() => {
    fetchSocioData(pagination, fetchParams);
  }, []);

  useEffect(() => {
    if ((elementoFormRecord && Object.keys(elementoFormRecord).length !== 0)
      || (!elementoFormRecord)) {
      setShowForm(true);
    }
  }, [elementoFormRecord]);

  const onTableChange = (
    newPagination,
    filters,
    sorter,
  ) => {
    setLoading(true);
    handleTableChange(
      newPagination,
      filters,
      sorter,
      fetchParams,
      (pagConfig, params) => {
        if (changeSelect === 'socios') {
          fetchSocioData(pagConfig, params);
        } else {
          fetchElementData(pagConfig, params);
        }
      },
    );
  };

  const onSubmitSocio = (values) => {
    if (values.id > 0) {
      authAxios.put(`${process.env.REACT_APP_API_URL}/api/socios/${values.id}/`, values)
        .then(() => {
          message.success('Socio editado');
          setTimeout(() => {
            setLoading(true);
            fetchSocioData(pagination, fetchParams);
            document.getElementById(`btn_hidden_${values.id}`).click();
          }, 2000);
        }).catch(() => {
          message.error('Error al editar datos del socio ');
        });
    } else {
      authAxios.post(`${process.env.REACT_APP_API_URL}/api/socios/`, values)
        .then(() => {
          message.success('Socio creado');
          setTimeout(() => {
            if (!document.getElementById('Idseguir')) {
              document.getElementById(`btn_hidden_${values.id}`).click();
              setLoading(true);
              fetchSocioData(pagination, fetchParams);
            } else {
              document.getElementById('btn_hidden_0').click();
              document.getElementById('btn0').click();
            }
          }, 2000);
        }).catch(() => {
          message.error('Error al crear socio');
        });
    }
  };

  const columnsElemento = () => [
    {
      /*  Hay que poner 'codigo' en dataIndex y key para que funcione el sort ascend y descend
      cuando se envía como parametro al endpoint /api/elementos/ */
      title: 'Tipo - Código',
      dataIndex: 'codigo',
      key: 'codigo',
      sorter: true,
      responsive: ['sm'],
      render: (_value, record) => (
        record.columna1
      ),
    },
    {
      title: 'Socio',
      dataIndex: 'columna2',
      key: 'columna2',
      responsive: ['sm'],
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          {record.estado ? <Tag color="#87d068">Activo</Tag> : <Tag color="#f50">Inactivo</Tag>}
          <SpanButton
            key={`button-editar-elemento-${record.id}`}
            onClick={() => setElementoFormRecord(record)}
          >
            Editar
          </SpanButton>

        </Space>
      ),
    },
  ];

  const columnsSocio = () => [
    {
      title: 'Nombre',
      dataIndex: 'columna1',
      key: 'columna1',
      responsive: ['sm'],
    },
    {
      title: 'Rut',
      dataIndex: 'columna2',
      key: 'columna2',
      responsive: ['sm'],
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          {record.estado ? <Tag color="#87d068">Activo</Tag> : <Tag color="#f50">Inactivo</Tag>}
          <ViewModal
            id={record.key}
            textBtn="Editar"
            titleModal="Editar Socio"
            typeBtn="link"
            body={(formSocio(record))}
          />
        </Space>
      ),
    },
  ];

  const agregarLoading = () => {
    if (!document.contains(document.getElementById('container_loader'))) {
      const elemDiv = document.createElement('div');
      elemDiv.id = 'container_loader';
      elemDiv.innerHTML = '<div class="loader"></div>';
      document.body.appendChild(elemDiv);
    }
  };

  const eliminarLoading = () => {
    if (document.contains(document.getElementById('container_loader'))) {
      document.getElementById('container_loader').remove();
    }
  };

  const valRut = (event) => {
    const temp = event.target.value;
    event.target.value = temp.toUpperCase();
  };

  const rutValidator = (rule, value) => {
    try {
      const count = (value.match(/-/g) || []).length;
      if (count > 0) {
        const dv = value.split('-');
        dv[1] = dv[1].toUpperCase();
        if (dv[0].length < 7) {
          throw new Error('RUT Incompleto');
        } else {
          let suma = 0;
          let multiplo = 2;
          const valor = dv[0] + dv[1];
          for (let i = 1; i <= dv[0].length; i += 1) {
            const index = multiplo * valor.charAt(dv[0].length - i);
            suma += index;

            if (multiplo < 7) { multiplo += 1; } else { multiplo = 2; }
          }

          const dvEsperado = 11 - (suma % 11);

          dv[1] = (dv[1] === 'K') ? 10 : dv[1];
          dv[1] = (dv[1] === '0') ? 11 : dv[1];
          if (dvEsperado !== Number(dv[1])) {
            throw new Error('RUT Inválido');
          } else {
            let isEncontrado = false;
            for (let i = 0; i < dataApi.length; i += 1) {
              if (value.trim() === dataApi[i].columna2) {
                isEncontrado = true;
              }
            }
            if (isEncontrado === true) {
              if (document.getElementById('complex-form_id').value === '0') {
                throw new Error('RUT o Pasaporte ya existe');
              } else {
                return Promise.resolve();
              }
            } else {
              return Promise.resolve();
            }
          }
        }
      } else {
        const re = /^[0-9\b]+$/;
        if (value.trim() !== '' && re.test(value)) {
          throw new Error('Pasaporte o RUT Inválido');
        } else {
          const countpoint = (value.match(/\./g) || []).length;
          if (countpoint > 0) {
            throw new Error('Pasaporte o RUT Inválido');
          } else {
            let encontradopass = false;
            for (let i = 0; i < dataApi.length; i += 1) {
              if (value.trim() === dataApi[i].columna2) {
                encontradopass = true;
              }
            }
            if (encontradopass === true) {
              if (document.getElementById('complex-form_id').value === '0') {
                throw new Error('RUT o Pasaporte ya existe');
              } else {
                return Promise.resolve();
              }
            } else {
              return Promise.resolve();
            }
          }
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const formSocio = (record) => {
    let values;
    const listaele = [];
    let checked = 'checked';
    if (record.key > 0) {
      const elem = JSON.parse(record.datos).elementos;
      if (JSON.parse(record.datos).estado === false) {
        checked = '';
      }
      if (elem) {
        elem.forEach((key) => {
          listaele.push({ id: key.id, label: `${key.tipo_nombre.toUpperCase()} - ${key.codigo}` });
        });
      }
      values = {
        id: record.key,
        first_name: JSON.parse(record.datos).first_name,
        last_name: JSON.parse(record.datos).last_name,
        rut: JSON.parse(record.datos).rut,
        estado: JSON.parse(record.datos).estado,
        is_preferencial: JSON.parse(record.datos).is_preferencial,
        elementos: listaele,
      };
    } else {
      values = {
        id: 0,
        estado: true,
        is_preferencial: false,
      };
    }
    return (
      <Form
        name="complex-form"
        onFinish={onSubmitSocio}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={values}
        autoComplete="off"
      >
        <Form.Item
          name="id"
          style={{ display: 'none' }}
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Estado">
          <Space>
            <Form.Item
              name="estado"
              noStyle
              rules={[{ required: false }]}
              valuePropName={checked}
            >
              <Switch />
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item label="Nombre">
          <Space>
            <Form.Item
              name="first_name"
              noStyle
              rules={[{ required: true, message: 'Nombre es obligatorio' }]}
            >
              <Input style={{ width: 300 }} />
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item label="Apellido">
          <Space>
            <Form.Item
              name="last_name"
              noStyle
              rules={[{ required: true, message: 'Apellido es obligatorio' }]}
            >
              <Input style={{ width: 300 }} />
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item label="Rut o Pasaporte">
          <Space>
            <Form.Item
              name="rut"
              noStyle
              rules={[{ required: true, message: 'Rut es obligatorio' },
                {
                  validator: rutValidator,
                }]}
            >
              <Input placeholder="99999999-9 (Sin puntos) para RUT" style={{ width: 300 }} onKeyUp={(event) => valRut(event)} />
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item label="Socio preferencial">
          <Space>
            <Form.Item
              name="is_preferencial"
              noStyle
            >
              <Select
                style={{ width: 300 }}
                options={[
                  {
                    value: true,
                    label: 'Si',
                  },
                  {
                    value: false,
                    label: 'No',
                  },
                ]}
              />
            </Form.Item>
          </Space>
        </Form.Item>
        {record.key > 0 && (
          <>
            <Divider orientation="left">Elementos Asociados</Divider>
            {listaele.length > 0 ? (
              <List
                bordered
                dataSource={listaele}
                renderItem={(item) => (
                  <List.Item>
                    {item.label}
                  </List.Item>
                )}
              />
            ) : ''}
          </>
        )}
        <Form.Item label=" " colon={false}>
          <Space>
            <Button type="primary" htmlType="submit">
              Guardar
            </Button>
          </Space>
        </Form.Item>
      </Form>
    );
  };

  const onChange = (event) => {
    setLoading(true);
    setChangeSelect(event);
    setTableSearchText('');
    if (event === 'socios') {
      fetchSocioData(defaultPagination, defaultParams);
    } else {
      fetchElementData(defaultPagination, defaultParams);
    }
  };

  const onChangeSearchValue = (value) => {
    setTableSearchText(value);
    if (changeSelect === 'socios') {
      fetchSocioData({
        ...pagination,
        current: 1,
      }, {
        ...fetchParams,
        offset: 0,
        search: value,
      });
    } else {
      fetchElementData({
        ...pagination,
        current: 1,
      }, {
        ...fetchParams,
        offset: 0,
        search: value,
      });
    }
  };

  return (
    <>
      <div className="home">
        <Row>
          <Col xs={12} sm={12} md={12} lg={4} xl={4}>
            <h1>Mantenedores</h1>
          </Col>
          <Col xs={12} sm={12} md={12} lg={20} xl={20}>
            <Tooltip placement="topLeft" title="Seleccione una opción">
              <Select
                defaultValue="socios"
                style={{ width: 120, float: 'right' }}
                onChange={onChange}
              >
                <Option value="socios">Socios</Option>
                <Option value="elementos">Elementos</Option>
              </Select>
            </Tooltip>
          </Col>
        </Row>
        <Space style={{ marginBottom: 16 }}>
          {loading ? agregarLoading() : eliminarLoading()}
          {errorDataMsg ? (
            <Alert message="Error al cargar los datos" type="error" showIcon />
          ) : null}
        </Space>
        <Row>
          <Col span={24}>
            <InputSearchWithKeyboard
              id="inputSearch"
              style={{ width: '50%', backgroundColor: 'white' }}
              placeholder={changeSelect === 'socios' ? 'Buscar por nombre o Rut...' : 'Buscar por Tipo, Código o Socio...'}
              onSearch={onChangeSearchValue}
            />
            { changeSelect === 'socios' ? (
              <ViewModal
                id={0}
                textBtn={textoBotonModal}
                titleModal={textoBotonModal}
                typeBtn="button"
                styleBtn={{ float: 'right' }}
                body={formSocio({ key: 0 })}
              />
            ) : (
              <Button
                key="button-crear-elemento"
                type="button"
                style={{ float: 'right' }}
                onClick={() => setElementoFormRecord(null)}
              >
                Crear Elemento
              </Button>
            )}
            <Table
              style={{ marginTop: '5px' }}
              columns={columns}
              dataSource={dataApi}
              pagination={pagination}
              onChange={onTableChange}
            />
          </Col>
        </Row>
      </div>
      {showForm && (
        <ElementoForm
          record={elementoFormRecord}
          setRecord={setElementoFormRecord}
          setShowForm={setShowForm}
          showForm={showForm}
          onFinish={() => {
            setLoading(true);
            fetchElementData({
              ...pagination,
              current: 1,
            }, {
              ...fetchParams,
              offset: 0,
              search: tableSearchText,
            });
          }}
        />
      )}
    </>
  );
};

export default Mantenedores;
