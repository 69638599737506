import { message } from 'antd';
import { authAxios } from '../utils/axios-instance';

export function setMovimientos(datos) {
  const tokenJson = JSON.parse(localStorage.getItem('token'));
  if (tokenJson) {
    const tokenType = tokenJson.token_type;
    const accessToken = tokenJson.access_token;
    authAxios.defaults.headers.Authorization = `${tokenType} ${accessToken}`;
    for (let i = 0; i < datos.length; i += 1) {
      const data = new FormData();
      data.append('socio', datos[i].socio);
      data.append('elemento', datos[i].elemento);
      let num_pal = '0';
      if (datos[i].num_palos !== '') {
        data.append('propiedades', `{"num_palos": ${datos[i].num_palos}}`);
        num_pal = datos[i].num_palos;
      } else {
        data.append('propiedades', '{}');
      }
      data.append('tipo', datos[i].tipo);
      const { tipo_el } = datos[i];
      /* let tipo = datos[i]['tipo']; */
      const { imgb64 } = datos[i];
      const { code_el } = datos[i];

      authAxios.post(`${process.env.REACT_APP_API_URL}/api/movimientos/`, {
        tipo: datos[i].tipo,
        elem: datos[i].elemento,
        soc: datos[i].socio,
        propiedades: `{"num_palos": ${num_pal}}`,
      })
        .then((response) => {
          if (tipo_el === '2') {
            const dataFoto = new FormData();
            dataFoto.append('nombre', `mov${response.data.id}_ele${code_el}`);
            dataFoto.append('urlimg', imgb64);
            dataFoto.append('movimiento', response.data.id);
            authAxios.post(`${process.env.REACT_APP_API_URL}/api/save_fotos/`, dataFoto)
              .then((savePhotosResponse) => {
                console.log(savePhotosResponse.data.message);
              }).catch((error) => {
                console.log(error.message);
              });
          }
        }).catch((error) => {
          console.log(error.message);
        });
    }
  } else {
    return 'error';
  }

  return 'ok';
}

export function updateElementos(datos) {
  const tokenJson = JSON.parse(localStorage.getItem('token'));
  if (tokenJson) {
    const tokenType = tokenJson.token_type;
    const accessToken = tokenJson.access_token;
    authAxios.defaults.headers.Authorization = `${tokenType} ${accessToken}`;
    for (let i = 0; i < datos.length; i += 1) {
      const data = new FormData();
      data.append('codigo', datos[i].codigo);
      data.append('contenedor', datos[i].contenedor);
      data.append('tipo', datos[i].tipo);

      authAxios.put(`${process.env.REACT_APP_API_URL}/api/elementos/${datos[i].id}/`, data)
        .then((response) => {
          console.log(response.data.message);
        }).catch((error) => {
          console.log(error.message);
        });
    }
  } else {
    return 'error';
  }

  return 'ok';
}

export function setConfigUser(datos) {
  const tokenJson = JSON.parse(localStorage.getItem('token'));
  if (tokenJson) {
    const tokenType = tokenJson.token_type;
    const accessToken = tokenJson.access_token;
    authAxios.defaults.headers.Authorization = `${tokenType} ${accessToken}`;
    const data = new FormData();
    data.append('user', datos.user);
    data.append('propiedades', `{"lector_externo": ${datos.lector_externo}}`);

    authAxios.post(`${process.env.REACT_APP_API_URL}/api/save_config/`, data)
      .then((response) => {
        const usData = JSON.parse(localStorage.getItem('userData'));
        const usDatanew = JSON.stringify({
          id: usData.id,
          email: usData.email,
          first_name: usData.first_name,
          last_name: usData.last_name,
          config_user: {
            user: datos.user,
            propiedades: { lector_externo: datos.lector_externo },
          },
        });
        localStorage.setItem('userData', usDatanew);
      }).catch((error) => {
        console.log(error.message);
      });
  } else {
    return 'error';
  }

  return 'ok';
}

export function updateUserData(values) {
  const { first_name, last_name } = values;
  const tokenJson = JSON.parse(localStorage.getItem('token'));
  if (tokenJson) {
    const tokenType = tokenJson.token_type;
    const accessToken = tokenJson.access_token;
    authAxios.defaults.headers.Authorization = `${tokenType} ${accessToken}`;
    const data = new FormData();
    data.append('first_name', first_name);
    data.append('last_name', last_name);
    const usData = JSON.parse(localStorage.getItem('userData'));
    data.append('email', usData.email);
    authAxios.put(`${process.env.REACT_APP_API_URL}/api/users/${usData.id}/`, data)
      .then((response) => {
        const usDatanew = JSON.stringify({
          id: response.data.id,
          email: response.data.email,
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          config_user: response.data.config_user,
        });
        localStorage.setItem('userData', usDatanew);
        message.success('Datos editados');
        setTimeout(() => { window.location.reload(); }, 2000);
      }).catch(() => {
        message.error('Los datos no se pudieron editar');
      });
  } else {
    return 'error';
  }

  return 'ok';
}
