import React from 'react';
import { Menu } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';

export default function MenuMobile() {
  const location = useLocation();
  const { SubMenu } = Menu;
  return (
    <>
      <Menu mode="horizontal" style={{ boxShadow: '0 0 0px rgba(0,0,0,.1)' }} defaultSelectedKeys={[location.pathname]}>
        <SubMenu key="menuMobile" title={<MenuOutlined style={{ fontSize: '24px' }} />}>
          <Menu.Item key="/"><NavLink to="/">Registrar</NavLink></Menu.Item>
          <Menu.Item key="/movimientos"><NavLink to="/movimientos">Activos</NavLink></Menu.Item>
          <Menu.Item key="/lockers"><NavLink to="/lockers">Lockers</NavLink></Menu.Item>
          <Menu.Item key="/elementos"><NavLink to="/elementos">Elementos</NavLink></Menu.Item>
          {/* <Menu.Item key="/mantenedores"><NavLink to="/mantenedores"> */}
          {/*  Mantenedores */}
          {/* </NavLink></Menu.Item> */}
          <SubMenu className="submenuAdmin" key="menuAdmin" title="Administrador">
            <Menu.Item key="/mantenedores"><NavLink to="/mantenedores">Mantenedores</NavLink></Menu.Item>
            <Menu.Item key="/reportes"><NavLink to="/reportes">Reportes</NavLink></Menu.Item>
          </SubMenu>
        </SubMenu>
      </Menu>
    </>
  );
}
