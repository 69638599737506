import { useState } from 'react';
import { authAxios } from '../utils/axios-instance';

export default function useUserData() {
  const getUserData = () => JSON.parse(localStorage.getItem('userData'));

  const [userData, setUserData] = useState(getUserData());

  const usesExternalReader = (
    userData
    && userData.config_user
    && userData.config_user.propiedades
    && userData.config_user.propiedades.lector_externo
  );

  const saveUserData = (data) => {
    localStorage.setItem('userData', JSON.stringify(data));
    setUserData(data);
  };

  return {
    setUserData: saveUserData,
    userData,
    usesExternalReader,
  };
}

export const fetchUserData = async () => authAxios.get(`${process.env.REACT_APP_API_URL}/api/users/me/`)
  .then((response) => response.data);
