import _ from 'lodash';

export const defaultPagination = {
  current: 1,
  pageSize: 10,
};

export const defaultParams = {
  limit: 10,
  offset: 0,
};

export function handleTableChange(
  newPagination,
  filters,
  sorter,
  fetchParams,
  fetchResults,
) {
  const pageSize = newPagination.pageSize || defaultPagination.pageSize;
  const current = newPagination.current || defaultPagination.current;

  const sortField = Array.isArray(sorter) ? sorter[0].columnKey : sorter.columnKey;
  const sortOrder = Array.isArray(sorter) ? sorter[0].order : sorter.order;
  const ordering = sortOrder ? `${sortOrder === 'descend' ? '-' : ''}${sortField}` : undefined;

  const filterParams = filters ? _.fromPairs(
    Object.keys(filters).filter(
      (value) => filters[value] !== null,
    ).map(
      (value) => [`${value}__in`, filters[value].join(',')],
    ),
  ) : {};

  fetchResults(newPagination, {
    ...fetchParams,
    limit: pageSize,
    offset: (current - 1) * pageSize,
    ordering,
    ...filterParams,
  });
}
