import axios from 'axios';

export const noAuthAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/o/token/`,
  timeout: 10000,
});

export const authAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/o/token/`,
  timeout: 10000,
});
