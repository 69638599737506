import React, { useState } from 'react';
import {
  Steps, Button, message, Row, Col,
} from 'antd';
/* import EscanearIng from "./stepsingresar/EscanearIng"; */
import EscanearIngreso from './stepsingresar/EscanearIngreso';
import { setMovimientos } from '../../hooks/ApiData';

const { Step } = Steps;

window.StepsData = '[]';

const steps = [
  {
    title: 'Escanear Objeto y Ubicación',
    content: <EscanearIngreso />,
    titleblock: 'Escanear Objeto',
  },

];

const StepsRecibir = () => {
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <>
      <Row type="flex" justify="center">
        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
          <Steps direction="vertical" current={current}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </Col>
        <Col xs={24} sm={16} md={16} lg={16} xl={16}>
          <div className="ant-steps-item-title-block">{steps[current].titleblock}</div>
          <div className="steps-content">{steps[current].content}</div>
          <div className="steps-action">
            {current > 0 && (
              <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                Atrás
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                Siguiente
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button type="primary" onClick={() => ProcesoGuardarI()}>
                Aceptar
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default StepsRecibir;

const ProcesoGuardarI = () => {
  const elements = document.getElementsByClassName('idsDataEnd');
  let validate = true;
  let mensaje = '';
  const dataSave = [];
  let casillero = '';
  for (let i = 0; i < elements.length; i += 1) {
    let validateLine = true;
    let urlimg = '';
    let num_palos = '';
    if (document.getElementById(`checkTipo_${elements[i].value.toString()}`).value !== '1') {
      if (document.getElementById(`checkScan_${elements[i].value.toString()}`).value === 'true') {
        if (document.getElementById(`checkTipo_${elements[i].value.toString()}`).value === '2') {
          num_palos = document.getElementById(`numinput_${elements[i].value.toString()}`).value.toString();
          urlimg = document.getElementById(`img_${elements[i].value.toString()}`).getElementsByClassName('ant-image-img')[0].getAttribute('src');
          if (document.getElementById(`checkTipo_${elements[i].value.toString()}`).value === '2') {
            if (document.getElementById(`checkNump_${elements[i].value.toString()}`).value === 'false') {
              validate = false;
              validateLine = false;
              mensaje = `Error Número Palos: ${document.getElementById(`checkCode_${elements[i].value.toString()}`).value}`;
              message.error(mensaje);
            } else {
              num_palos = document.getElementById(`numinput_${elements[i].value.toString()}`).value.toString();
            }
            if (document.getElementById(`checkFoto_${elements[i].value.toString()}`).value === 'false') {
              validate = false;
              validateLine = false;
              mensaje = `Error Foto: ${document.getElementById(`checkCode_${elements[i].value.toString()}`).value}`;
              message.error(mensaje);
            } else {
              urlimg = document.getElementById(`img_${elements[i].value.toString()}`).getElementsByClassName('ant-image-img')[0].getAttribute('src');
            }
          }
        }
        if (validateLine === true) {
          const lineSave = {
            socio: document.getElementById(`socio_${elements[i].value.toString()}`).value,
            elemento: elements[i].value.toString(),
            propiedades: '{}',
            tipo: 'entrada',
            num_palos,
            imgb64: urlimg,
            tipo_el: document.getElementById(`checkTipo_${elements[i].value.toString()}`).value,
            code_el: document.getElementById(`checkCode_${elements[i].value.toString()}`).value,
          };
          dataSave.push(lineSave);
        }
      }
    } else if (document.getElementById(`checkScan_${elements[i].value.toString()}`).value === 'true') {
      casillero = elements[i].value.toString();
    }
  }

  if (validate === true && dataSave.length > 0) {
    if (casillero !== '') {
      const resp = setMovimientos(dataSave);
      if (resp === 'ok') {
        message.success('Proceso completo');
        setTimeout(() => { window.location.reload(); }, 2000);
      } else {
        message.error('Hubo un error en el sistema');
      }
    } else {
      message.error('No hay casilleros escaneados');
    }
  } else {
    message.error('El proceso tuvo errores');
  }
};
