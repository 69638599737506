import React from 'react';
import { Menu, Button, Avatar } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import useUserData from '../../hooks/useUserData';
import logout from '../../utils/logout';

export default function MenuRight() {
  const { userData } = useUserData();
  const { email } = userData;
  const { SubMenu } = Menu;

  return (
    <>
      <Menu mode="horizontal">
        <SubMenu key="SubMenu" className="user-submenu" title={<Avatar>USER</Avatar>}>
          <Menu.Item key="/manual"><a href="https://help.casillero.online/">Manual del Usuario</a></Menu.Item>
          <Menu.Item key="/aboutus"><NavLink to="/aboutus">Sobre el Sistema</NavLink></Menu.Item>
          <Menu.Item key="/cuenta"><NavLink to="/cuenta">Cuenta</NavLink></Menu.Item>
          <Menu.ItemGroup title={email}>
            <Menu.Item key="setting:1" icon={<LogoutOutlined />}>
              <Button type="link" onClick={logout}>Cerrar sesión</Button>
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>
      </Menu>
    </>
  );
}
