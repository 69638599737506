import React from 'react';
import {
  Form,
  Switch,
  Card, message, Input, Button,
} from 'antd';
import { setConfigUser, updateUserData } from '../hooks/ApiData';

const Cuenta = () => {
  const usData = JSON.parse(localStorage.getItem('userData'));
  let lector_ex = true;
  let propValue = 'checked';
  const { first_name } = usData;
  const { last_name } = usData;
  if (usData.config_user && usData.config_user.propiedades) {
    lector_ex = usData.config_user.propiedades.lector_externo;
    if (!lector_ex) {
      propValue = '';
    }
  }
  const handleValChange = (checked) => {
    if (checked === false) {
      message.success('Lector externo desactivado');
    } else {
      message.success('Lector externo activado');
    }

    const lineSave = {
      user: usData.id,
      lector_externo: checked,
    };
    setConfigUser(lineSave);
  };

  return (
    <Card>
      <Form
        onFinish={updateUserData}
      >
        <Form.Item
          label="Nombre"
          name="first_name"
          initialValue={first_name}
          rules={[
            {
              required: true,
              message: 'Por favor ingrese el nombre',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Apellido"
          name="last_name"
          initialValue={last_name}
          rules={[
            {
              required: true,
              message: 'Por favor ingrese el apellido',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="lector_externo"
          label="Lector Externo"
          initialValue={lector_ex}
          valuePropName={propValue}
        >
          <Switch onChange={handleValChange} />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            span: 12,
            offset: 6,
          }}
        >
          <Button type="primary" htmlType="submit">
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Cuenta;
