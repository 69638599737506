import {
  Table, Button, Row, Tooltip, Alert, Col, List, DatePicker,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { LoadingOutlined, PrinterOutlined } from '@ant-design/icons';
import GenerateQr from '../utils/common/GenerateQr';
import { authAxios } from '../utils/axios-instance';
import { InputSearchWithKeyboard } from '../components/InputWithKeyboard';
import { capitalize } from '../utils/capitalize';
import { defaultPagination, defaultParams, handleTableChange } from '../utils/tables';

const { RangePicker } = DatePicker;

const ElementosList = () => {
  const [elementos, setElementos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [errorDataMsg, setErrorDataMsg] = useState(false);
  const abrevTipoElemento = ['', 'CA', 'BO', 'CM', 'CE', 'BA'];
  const [pagination, setPagination] = useState(defaultPagination);
  const [fetchParams, setFetchParams] = useState(defaultParams);

  const fetchData = (
    newPagination,
    params = {},
  ) => new Promise(
    (resolve, reject) => {
      authAxios.get(
        `${process.env.REACT_APP_API_URL}/api/elementos/`,
        {
          params: {
            ...params,
            estado: true,
          },
        },
      ).then((response) => {
        setElementos(response.data.results);
        setPagination({
          ...newPagination,
          total: response.data.count,
        });
        setFetchParams(params);
        resolve(response.data.results);
      }).catch((e) => {
        setErrorDataMsg(true);
        reject(e);
      });
    },
  );

  useEffect(() => {
    fetchData(pagination, fetchParams).then(() => setLoading(false));
  }, []);

  const onTableChange = (
    newPagination,
    filters,
    sorter,
  ) => {
    handleTableChange(
      newPagination,
      filters,
      sorter,
      fetchParams,
      (pagConfig, params) => {
        fetchData(pagConfig, params).then(() => setLoading(false));
      },
    );
  };

  const onChangeSearchValue = (value) => {
    setLoadingSearch(true);
    fetchData({
      ...pagination,
      current: 1,
    }, {
      ...fetchParams,
      offset: 0,
      search: value,
    }).then(() => setLoadingSearch(false));
  };

  const onChangeDateRange = (dates) => {
    if (dates && dates.length === 2) {
      setLoading(true);
      fetchData(pagination, {
        ...fetchParams,
        creado_el__gte: dates[0].startOf('day').format(),
        creado_el__lte: dates[1].endOf('day').format(),
      }).then(() => setLoading(false));
    } else {
      setLoading(true);
      fetchData(pagination, {
        ...fetchParams,
        creado_el__gte: undefined,
        creado_el__lte: undefined,
      }).then(() => setLoading(false));
    }
  };

  const agregarLoading = () => {
    if (!document.contains(document.getElementById('container_loader'))) {
      const elemDiv = document.createElement('div');
      elemDiv.id = 'container_loader';
      elemDiv.innerHTML = '<div class="loader"></div>';
      document.body.appendChild(elemDiv);
    }
  };

  const eliminarLoading = () => {
    if (document.contains(document.getElementById('container_loader'))) {
      document.getElementById('container_loader').remove();
    }
  };

  const renderSocio = (elemento) => {
    if (elemento.first_name || elemento.last_name) {
      return `${elemento.first_name || ''} ${elemento.last_name || ''}`.trim();
    }
    return '';
  };

  const imprimirTodo = () => {
    setLoadingPrint(true);
    authAxios.get(
      `${process.env.REACT_APP_API_URL}/api/elementos/request_qrs_pdf/`,
      {
        params: {
          ...fetchParams,
          estado: true,
          limit: undefined,
          offset: undefined,
        },
      },
    ).then((response) => {
      if (response.data.task_id) {
        const taskId = response.data.task_id;
        const intervalId = setInterval(() => {
          authAxios.get(
            `${process.env.REACT_APP_API_URL}/api/elementos/qrs_pdf_status/?task_id=${taskId}`,
          ).then((statusResponse) => {
            if (statusResponse.data.status === 'FAILURE') {
              setErrorDataMsg(true);
              setLoadingPrint(false);
              clearInterval(intervalId);
            } else if (statusResponse.data.file_url) {
              const url = statusResponse.data.file_url;
              const link = document.createElement('a');
              link.href = url;
              document.body.appendChild(link);
              link.click();
              window.URL.revokeObjectURL(url);
              setErrorDataMsg(false);
              setLoadingPrint(false);
              clearInterval(intervalId);
            }
          }).catch(() => {
            setErrorDataMsg(true);
            setLoadingPrint(false);
            clearInterval(intervalId);
          });
        }, 5000);
      } else {
        setErrorDataMsg(true);
        setLoadingPrint(false);
      }
    }).catch(() => {
      setErrorDataMsg(true);
      setLoadingPrint(false);
    });
  };
  const columns = [
    {
      title: 'Tipo - Código - Socio',
      dataIndex: 'codigo',
      key: 'codigo',
      sorter: true,
      render: (_value, record) => {
        let renderValue = `${capitalize(record.tipo_nombre)} - ${record.codigo}`;
        const socio = renderSocio(record);
        if (socio) {
          renderValue += ` - ${socio}`;
        }
        if (record.rut) {
          renderValue += ` (${record.rut})`;
        }
        return renderValue;
      },
    },

    {
      title: 'Código QR',
      dataIndex: 'datos',
      render: (_value, record) => (
        <>
          <p>
            Código manual:
            {' '}
            {abrevTipoElemento[record.tipo] + record.id}
          </p>
          <GenerateQr
            datos={record}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <div className="home">
        <Row justify="space-between">
          <Col>
            <h1>Descargas de Códigos QR</h1>
            {loading ? agregarLoading() : eliminarLoading()}
            {errorDataMsg
              ? <Alert message="Error al cargar los datos" type="error" showIcon /> : null}
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1}>
            <Tooltip placement="topLeft" title="Imprimir todos los códigos">
              <Button
                onClick={imprimirTodo}
                icon={loadingPrint ? <LoadingOutlined /> : <PrinterOutlined />}
                style={{ float: 'right' }}
              />
            </Tooltip>
          </Col>
        </Row>
        <Row justify="space-between" gutter={[16, 16]} style={{ marginBottom: 15 }}>
          <Col xs={24} sm={24} md={14} lg={16} xl={16}>
            <InputSearchWithKeyboard
              style={{ width: '100%' }}
              placeholder="Buscar por Tipo, Código o Socio..."
              onSearch={onChangeSearchValue}
              loading={loadingSearch}
            />
          </Col>
          <Col xs={24} sm={12} md={10} lg={8} xl={8}>
            <RangePicker onChange={onChangeDateRange} style={{ width: '100%' }} />
          </Col>
        </Row>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={elementos}
          pagination={pagination}
          onChange={onTableChange}
        />
        <List
          style={{ display: 'none' }}
          dataSource={elementos}
          renderItem={(item) => (
            <List.Item>
              <GenerateQr
                datos={item}
              />
            </List.Item>
          )}
        />
      </div>
    </>
  );
};

export default ElementosList;
