import React from 'react';
import { Menu } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import useUserData from '../hooks/useUserData';

const { SubMenu } = Menu;

export default function Navigation() {
  const { userData } = useUserData();
  const { groups } = userData;
  const location = useLocation();
  let reportes = '';

  if (groups !== undefined && groups[0] === 1) {
    reportes = <Menu.Item key="/reportes"><NavLink to="/reportes">Reportes</NavLink></Menu.Item>;
  }
  return (
    <>
      <Menu
        mode="horizontal"
        style={{ width: '500px' }}
        className="menuPrincipal"
        defaultSelectedKeys={[location.pathname]}
      >
        <Menu.Item key="/"><NavLink to="/">Registrar</NavLink></Menu.Item>
        <Menu.Item key="/movimientos"><NavLink to="/movimientos">Activos</NavLink></Menu.Item>
        <Menu.Item key="/lockers"><NavLink to="/lockers">Lockers</NavLink></Menu.Item>
        <Menu.Item key="/elementos"><NavLink to="/elementos">Elementos</NavLink></Menu.Item>
        <SubMenu className="submenuAdmin" key="menuAdmin" title="Administrador">
          <Menu.Item key="/mantenedores">
            <NavLink to="/mantenedores">Mantenedores</NavLink>
          </Menu.Item>
          {reportes}
        </SubMenu>
      </Menu>

    </>
  );
}
