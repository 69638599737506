import React, { useState } from 'react';
import {
  message, Button, Form, Input, Image, Layout, Typography, Space, Switch,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKeyboard } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import _ from 'lodash';
import queryString from 'query-string';

import { isMobile } from 'react-device-detect';
import homeImage from '../assets/images/pwcc.jpeg';
import FormWithKeyboard from '../components/FormWithKeyboard';

const { Sider, Content } = Layout;
const { Text, Title } = Typography;

const LoginContent = styled(Content)`
  background-color: white;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Login = ({ setToken }) => {
  const [enableKeyboard, setEnableKeyboard] = useState(false);

  const login = async (values) => {
    const { username, password } = values;
    await fetch(`${process.env.REACT_APP_API_URL}/o/token/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: queryString.stringify({
        grant_type: 'password',
        username,
        password,
        client_id: process.env.REACT_APP_CLIENT_ID,
      }),
    })
      .then((data) => data.json())
      .then((json) => {
        if (!_.isEmpty(json) && 'access_token' in json && 'refresh_token' in json) {
          setToken(json);
        } else {
          message.error('Credenciales inválidas');
        }
      });
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        width="65%"
        breakpoint="md"
        collapsedWidth="0"
        theme="light"
        zeroWidthTriggerStyle={{ display: 'none' }}
      >
        <Image
          style={{ objectFit: 'cover' }}
          width="100%"
          height="100%"
          src={homeImage}
          preview={false}
        />
      </Sider>
      <LoginContent>
        <Title level={2}>Bienvenido</Title>
        <Text>Inicia sesión</Text>
        <FormWithKeyboard
          inputIds={['username', 'password']}
          enableKeyboard={enableKeyboard}
          name="basic"
          layout="vertical"
          style={{ marginTop: 5, marginBottom: 5 }}
          onFinish={login}
        >
          <Form.Item
            label="Correo electrónico"
            name="username"
            rules={[{ required: true, message: 'Ingresa un correo válido' }]}
          >
            <Input id="username" autoCapitalize="off" type="email" readOnly={enableKeyboard} />
          </Form.Item>

          <Form.Item
            label="Contraseña"
            name="password"
            rules={[{ required: true, message: 'Ingresa tu contraseña' }]}
          >
            <Input.Password id="password" readOnly={enableKeyboard} />
          </Form.Item>

          <Space>
            <Button type="primary" htmlType="submit">
              Ingresar
            </Button>
            {isMobile && (
              <Switch
                checkedChildren={<FontAwesomeIcon icon={faKeyboard} />}
                unCheckedChildren={<FontAwesomeIcon icon={faKeyboard} />}
                onChange={() => setEnableKeyboard(!enableKeyboard)}
              />
            )}
          </Space>
        </FormWithKeyboard>
      </LoginContent>
    </Layout>
  );
};

export default Login;
