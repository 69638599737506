import React, { useEffect, useState } from 'react';
import {
  Row, List, Card, Badge, Tag, Alert, Tooltip, Col, Modal, Space, Descriptions,
} from 'antd';
import { EnvironmentOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { authAxios } from '../utils/axios-instance';
import { InputSearchWithKeyboard } from '../components/InputWithKeyboard';
import { defaultPagination, defaultParams, handleTableChange } from '../utils/tables';

const ElementoDescription = ({ elemento = null }) => {
  function renderElementoStatusTag() {
    let elementoStatus;
    let tagColor;
    if (elemento) {
      if (
        elemento.latest_movimiento !== null
        && elemento.latest_movimiento.tipo === 'salida'
      ) {
        elementoStatus = 'En uso';
        tagColor = 'green';
      } else if (
        elemento.latest_movimiento !== null
        && elemento.latest_movimiento.tipo === 'entrada'
      ) {
        const movimientoDate = new Date(elemento.latest_movimiento.realizado_el);
        elementoStatus = `Último movimiento ${movimientoDate.toLocaleString()}`;
        tagColor = 'red';
      }
      if (elementoStatus) {
        return <Tag color={tagColor}>{elementoStatus}</Tag>;
      }
    }
    return <></>;
  }

  return (
    <Descriptions column={1}>
      <Descriptions.Item label={<Badge status={elemento ? 'error' : 'success'} text="Estado" />}>
        {elemento ? 'Ocupado' : 'Disponible'}
      </Descriptions.Item>
      <Descriptions.Item
        label={(
          <span>
            <EnvironmentOutlined />
            {' '}
            Socio
          </span>
        )}
      >
        {elemento ? `${elemento.first_name} ${elemento.last_name}` : 'S/I'}
      </Descriptions.Item>
      <Descriptions.Item
        label={(
          <span>
            <PlusOutlined />
            {' '}
            Tipo Objeto
          </span>
        )}
      >
        {elemento ? `${elemento.tipo_nombre.charAt(0).toUpperCase() + elemento.tipo_nombre.slice(1)}` : 'S/I'}
      </Descriptions.Item>
      <Descriptions.Item
        label={(
          <span>
            <PlusOutlined />
            {' '}
            Código Objeto
          </span>
        )}
      >
        {elemento ? (
          <Space>
            {elemento.codigo}
            {renderElementoStatusTag()}
          </Space>
        ) : 'Libre'}
      </Descriptions.Item>
    </Descriptions>
  );
};

const Lockers = () => {
  const [lockers, setLockers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorDataMsg, setErrorDataMsg] = useState(false);
  const [fetchParams, setFetchParams] = useState(defaultParams);
  const [pagination, setPagination] = useState(defaultPagination);
  const [searchElementOrSocio, setSearchElementOrSocio] = useState('');
  const [resultSearchElementOrSocio, setResultSearchElementOrSocio] = useState({});
  const [selectedLocker, setSelectedLocker] = useState();
  const [showLockerDetails, setShowLockerDetails] = useState(false);
  const abrevTipoElemento = ['LOCK', 'CA', 'BO', 'CM', 'CE', 'BA'];

  const onDataUpdate = (newPagination, params, response) => {
    setPagination({
      ...newPagination,
      total: response.data.count,
    });
    setFetchParams(params);
    setLockers(response.data.results);
    setErrorDataMsg(false);
    setLoading(false);
  };

  const fetchDataByLockers = (newPagination, params) => {
    setSearchElementOrSocio('');
    setLoading(true);
    authAxios.get(
      `${process.env.REACT_APP_API_URL}/api/elementos/`,
      {
        params: {
          ...params,
          tipo: 1,
          estado: true,
          search: '',
        },
      },
    ).then((response) => {
      onDataUpdate(newPagination, params, response);
    }).catch(() => {
      setLoading(false);
      setErrorDataMsg(true);
    });
  };

  useEffect(() => {
    fetchDataByLockers(pagination, fetchParams);
  }, []);

  const fetchDataByElementOrSocio = (newPagination, params) => {
    setLoading(true);
    authAxios.get(
      `${process.env.REACT_APP_API_URL}/api/elementos/search_lockers_by_element_or_socio/`,
      {
        params: {
          ...params,
          codigo__istartswith: '',
          estado: true,
        },
      },
    ).then((response) => {
      onDataUpdate(newPagination, params, response);
    }).catch(() => {
      setLoading(false);
      setErrorDataMsg(true);
    });
  };

  const onTableChange = (
    page,
    pageSize,
  ) => {
    if (searchElementOrSocio) {
      handleTableChange(
        { ...pagination, current: page, pageSize },
        {},
        {},
        fetchParams,
        (pagConfig, params) => {
          fetchDataByElementOrSocio(pagConfig, params);
        },
      );
    } else {
      handleTableChange(
        { ...pagination, current: page, pageSize },
        {},
        {},
        fetchParams,
        (pagConfig, params) => {
          fetchDataByLockers(pagConfig, params);
        },
      );
    }
  };

  const agregarLoading = () => {
    if (!document.contains(document.getElementById('container_loader'))) {
      const elemDiv = document.createElement('div');
      elemDiv.id = 'container_loader';
      elemDiv.innerHTML = '<div class="loader"></div>';
      document.body.appendChild(elemDiv);
    }
  };

  const eliminarLoading = () => {
    if (document.contains(document.getElementById('container_loader'))) {
      document.getElementById('container_loader').remove();
    }
  };

  const onChangeSearchLockersValue = (value) => {
    fetchDataByLockers({
      ...pagination,
      current: 1,
    }, {
      ...fetchParams,
      offset: 0,
      codigo__istartswith: value,
    });
  };

  const onChangesearchElementOrSocio = (value) => {
    setSearchElementOrSocio(value);
    if (value) {
      const params = {
        ...fetchParams,
        offset: 0,
        search: value,
        codigo__istartswith: '',
      };
      setLoading(true);
      authAxios.get(
        `${process.env.REACT_APP_API_URL}/api/elementos/search_lockers_by_element_or_socio/`,
        {
          params: {
            ...params,
            estado: true,
          },
        },
      ).then((response) => {
        setResultSearchElementOrSocio({ value, response, params });
      }).catch(() => {
        setLoading(false);
        setErrorDataMsg(true);
      });
    } else {
      onChangeSearchLockersValue('');
    }
  };

  useEffect(() => {
    if (resultSearchElementOrSocio.value === searchElementOrSocio) {
      onDataUpdate({
        ...pagination,
        current: 1,
      }, resultSearchElementOrSocio.params, resultSearchElementOrSocio.response);
    }
  }, [resultSearchElementOrSocio]);

  return (
    <>
      <div className="home">
        <Row>
          <h1>Estatus de Casilleros</h1>
          &nbsp;
          <h3>
            <Tooltip placement="topLeft" title="CA: Casillero; BO: Bolsa; CM: Carro Manual; CE: Carro Eléctrico; BA: Bateria">
              <InfoCircleOutlined style={{ cursor: 'pointer' }} />
            </Tooltip>
          </h3>
          {loading ? agregarLoading() : eliminarLoading()}
          {errorDataMsg ? <Alert message="Error al cargar los datos" type="error" showIcon /> : null}
        </Row>
        <Row style={{ backgroundColor: 'white' }}>
          <Col xs={24} sm={24} md={11} lg={11} xl={11}>
            <InputSearchWithKeyboard
              style={{ width: '100%' }}
              placeholder="Buscar Casilleros..."
              onSearch={onChangeSearchLockersValue}
            />
          </Col>
          <Col xs={0} sm={0} md={1} lg={1} xl={1}>&nbsp;</Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <InputSearchWithKeyboard
              style={{ width: '100%' }}
              placeholder="Buscar Elementos o Socios..."
              onSearch={onChangesearchElementOrSocio}
            />
          </Col>
        </Row>
        <Row>&nbsp;</Row>
        <Row justify="center">
          <Col span={24}>
            <List
              grid={{
                gutter: 16,
                xs: 2,
                sm: 2,
                md: 4,
                lg: 4,
                xl: 6,
                xxl: 6,
              }}
              dataSource={lockers}
              pagination={{ ...pagination, onChange: onTableChange }}
              renderItem={(item) => (
                <List.Item>
                  <Card
                    key={item.id}
                    title={item.codigo}
                    hoverable
                    onClick={() => {
                      setSelectedLocker(item);
                      setShowLockerDetails(true);
                    }}
                  >
                    {item.elementos.length ? (
                      item.elementos.map((elemento) => (
                        <div key={elemento.id}>
                          <Badge
                            status="error"
                            text={`${abrevTipoElemento[elemento.tipo]} ${elemento.codigo}`}
                          />
                        </div>
                      ))
                    ) : <Badge status="success" text="Libre" />}
                  </Card>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </div>

      {selectedLocker && (
        <Modal
          destroyOnClose
          title="Información de la Casilla"
          visible={showLockerDetails}
          onCancel={() => setShowLockerDetails(false)}
          footer={null}
        >
          <span>
            <b>Tipo elementos permitidos: </b>
            {
              selectedLocker.tipos_permitidos.length > 0
                ? selectedLocker.tipos_permitidos.map((tipo) => tipo.codigo).join(' ')
                : 'BO CM CE BA'
            }
          </span>
          {selectedLocker.elementos.length > 0 ? (
            <List
              dataSource={selectedLocker.elementos}
              renderItem={(item) => (
                <List.Item>
                  <ElementoDescription elemento={item} />
                </List.Item>
              )}
            />
          ) : <ElementoDescription /> }
        </Modal>
      )}
    </>
  );
};

export default Lockers;
