import React, { useEffect, useState } from 'react';
import {
  Row, Col, Card, DatePicker, Alert, AutoComplete, Input, Space, Button, Select, message,
} from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import { saveAs } from 'file-saver';
import { authAxios } from '../utils/axios-instance';

const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';
const unmes = new Date();
unmes.setMonth(unmes.getMonth() - 1);

const Reportes = () => {
  const [initLoading, setInitLoading] = useState(false);
  const [errorDataMsg, setErrorDataMsg] = useState(false);
  const [listaSocios, setListaSocios] = useState([]);
  const [listaElementos, setListaElementos] = useState([]);
  const [listaElementosFilter, setListaElementosFilter] = useState([]);
  const listaTipo = [
    { label: 'Todos', value: 0 },
    { label: 'Bolso', value: 2 },
    { label: 'Carro de golf manual', value: 3 },
    { label: 'Carro de golf eléctrico', value: 4 },
    { label: 'Batería', value: 5 },
  ];
  const [options, setOptions] = useState('');
  const [optionsElem, setOptionsElem] = useState('');
  const [idSocio, setIdSocio] = useState('');
  const [idElemento, setIdElemento] = useState('');
  const [nombreSocio, setNombreSocio] = useState('');
  const [nombreElemento, setNombreElemento] = useState('');
  const [idTipo, setIdTipo] = useState(0);
  const [rangoFecha, setRangoFecha] = useState([]);
  const [rangoFechaMoment, setRangoFechaMoment] = useState([]);
  const abrevTipoElemento = ['', 'CA', 'BO', 'CM', 'CE', 'BA'];

  const renderItem = (key, value, rut, rutsearch) => ({
    key,
    value,
    rut,
    label: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {value}
        <span style={{ color: '#6d6d6d' }}>
          <i><b>{rutsearch}</b></i>
        </span>
      </div>
    ),
  });

  const renderItemElem = (key, value, tipo_nombre) => ({
    key,
    value,
    tipo_nombre,
    label: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {value}
      </div>
    ),
  });

  const agregarLoading = () => {
    if (!document.contains(document.getElementById('container_loader'))) {
      const elemDiv = document.createElement('div');
      elemDiv.id = 'container_loader';
      elemDiv.innerHTML = '<div class="loader"></div>';
      document.body.appendChild(elemDiv);
    }
  };

  const eliminarLoading = () => {
    if (document.contains(document.getElementById('container_loader'))) {
      document.getElementById('container_loader').remove();
    }
  };

  const onInit = (val) => {
    setIdElemento('');
    setNombreElemento('');
    if (val !== '') {
      const filtered = listaSocios.filter(
        (obj) => obj.key !== 0
              && obj.valuesearch
                .toString(),
      );

      const opciones = [];
      filtered.forEach((line) => {
        opciones.push(renderItem(line.key, line.value, line.rut, line.rutsearch));
      }, this);

      setOptions(opciones);
    }
  };

  const onInitElem = (val) => {
    if (val !== '') {
      let filtered;
      if (nombreSocio !== '') {
        filtered = listaElementosFilter.filter(
          (obj) => obj.key !== 0
                    && obj.valuesearch
                      .toString(),
        );
      } else {
        filtered = listaElementos.filter(
          (obj) => obj.key !== 0
                    && obj.valuesearch
                      .toString(),
        );
      }
      const opciones = [];
      filtered.forEach((line) => {
        opciones.push(renderItemElem(line.key, line.value, line.tipo_nombre));
      }, this);

      setOptionsElem(opciones);
    }
  };

  const onSearch = (val) => {
    const filtered = listaSocios.filter(
      (obj) => obj.key !== 0
            && obj.valuesearch
              .toString()
              .toLowerCase()
              .includes(val.toLowerCase()),
    );

    const opciones = [];
    filtered.forEach((line) => {
      opciones.push(renderItem(line.key, line.value, line.rut, line.rutsearch));
    }, this);

    setOptions(opciones);
    setNombreSocio(val);
  };

  const onSearchElem = (val) => {
    const filtered = listaElementosFilter.filter(
      (obj) => obj.key !== 0
            && obj.valuesearch
              .toString()
              .toLowerCase()
              .includes(val.toLowerCase()),
    );

    const opciones = [];
    filtered.forEach((line) => {
      opciones.push(renderItemElem(line.key, line.value, line.tipo_nombre));
    }, this);

    setOptionsElem(opciones);
    setNombreElemento(val);
  };

  const onSelect = (val, option) => {
    setIdSocio(option.key);
    setNombreSocio(option.value);

    const filtered = listaElementos.filter(
      (obj) => obj.key !== 0
              && obj.socio === option.key,
    );

    setListaElementosFilter(filtered);
  };

  const onSelectElem = (val, option) => {
    setIdElemento(option.key);
    setNombreElemento(option.value);
  };

  const cleanFilterMov = () => {
    setIdSocio('');
    setIdElemento('');
    setRangoFecha([]);
    setRangoFechaMoment([]);
    setNombreSocio('');
    setNombreElemento('');
  };

  const cleanFilterInv = () => {
    setIdTipo(0);
  };

  const changeDateRange = (value, dateString) => {
    setRangoFecha(dateString);
    setRangoFechaMoment(value);
  };

  const changeTipo = (event) => {
    setIdTipo(event);
  };

  const generarDocMov = () => {
    if (rangoFecha.length > 0) {
      const tokenJson = JSON.parse(localStorage.getItem('token'));
      setInitLoading(true);
      if (tokenJson) {
        const tokenType = tokenJson.token_type;
        const accessToken = tokenJson.access_token;
        authAxios.defaults.headers.Authorization = `${tokenType} ${accessToken}`;
        const dataMov = new FormData();
        dataMov.append('idElemento', idElemento);
        dataMov.append('idSocio', idSocio);
        dataMov.append('rangoFecha', JSON.stringify(rangoFecha));

        authAxios.post(`${process.env.REACT_APP_API_URL}/api/documento_movimientos/`, {
          idElemento,
          idSocio,
          rangoFecha: JSON.stringify(rangoFecha),
        }, {
          maxContentLength: Infinity,
          timeout: 60000,
          responseType: 'blob',
        })
          .then((response) => {
            saveAs(response.data, `reporte_movimientos_${Date.now()}.xlsx`);
            setInitLoading(false);
          }).catch((response) => {
            setInitLoading(false);
            setErrorDataMsg(true);
          });
      }
    } else {
      message.error('Rango de fechas vacío');
    }
  };

  const generarDocInv = () => {
    const tokenJson = JSON.parse(localStorage.getItem('token'));
    setInitLoading(true);
    if (tokenJson) {
      const tokenType = tokenJson.token_type;
      const accessToken = tokenJson.access_token;
      authAxios.defaults.headers.Authorization = `${tokenType} ${accessToken}`;
      const dataInv = new FormData();
      dataInv.append('idTipo', idTipo);

      authAxios.post(`${process.env.REACT_APP_API_URL}/api/documento_inventario/`, dataInv,
        { maxContentLength: Infinity, timeout: 60000, responseType: 'blob' })
        .then((response) => {
          saveAs(response.data, `reporte_inventario_${Date.now()}.xlsx`);
          setInitLoading(false);
        }).catch((response) => {
          setInitLoading(false);
          setErrorDataMsg(true);
        });
    }
  };

  const buscarSocioOnFocus = (val) => {
    if (val === '') {
      setIdSocio('');
      setNombreSocio('');
    } else {
      const encontrado = listaSocios.filter(
        (obj) => obj.key !== 0
                  && obj.valuesearch
                    .toString()
                    .toLowerCase()
                    .includes(val.toLowerCase()),
      );

      if (encontrado.length === 1) {
        const opc = [];
        encontrado.forEach((line) => {
          opc.push(line.key, line.value);
        }, this);
        setIdSocio(opc[0]);
        setNombreSocio(opc[1]);
      } else {
        setIdSocio('');
        setNombreSocio('');
      }
    }
  };

  useEffect(() => {
    setInitLoading(true);
    authAxios.get(
      `${process.env.REACT_APP_API_URL}/api/socios/`,
      {
        maxContentLength: Infinity,
        timeout: 60000,
        params: {
          estado: true,
        },
      },
    ).then((response) => {
      const newListaSocios = [];
      Object.keys(response.data).forEach((key) => {
        let rutsearch = response.data[key].rut;
        if (response.data[key].rut === '11111111-1') {
          rutsearch = 'Sin Rut';
        }
        newListaSocios.push({
          key: response.data[key].id,
          value: `${response.data[key].first_name} ${response.data[key].last_name}`,
          valuesearch: `${response.data[key].first_name} ${response.data[key].last_name} ${rutsearch}`,
          rut: response.data[key].rut,
          rutsearch,
        });
      });
      setListaSocios(newListaSocios);
      setInitLoading(false);
    }).catch((response) => {
      setInitLoading(false);
      setErrorDataMsg(true);
    });

    authAxios.get(
      `${process.env.REACT_APP_API_URL}/api/elementos/?estado=True`,
      { maxContentLength: Infinity, timeout: 60000 },
    ).then((response) => {
      const newListaElementos = [];
      Object.keys(response.data).forEach((key) => {
        newListaElementos.push({
          key: response.data[key].id,
          value: abrevTipoElemento[response.data[key].tipo] + response.data[key].codigo,
          socio: response.data[key].socio,
          tipo_nombre: response.data[key].tipo_nombre,
          valuesearch: `${response.data[key].codigo} ${response.data[key].tipo_nombre}`,
        });
      });
      setListaElementos(newListaElementos);
      setListaElementosFilter(newListaElementos);
      setInitLoading(false);
    }).catch(() => {
      setInitLoading(false);
      setErrorDataMsg(true);
    });
  }, []);

  return (
    <>
      <div className="home">
        {initLoading ? agregarLoading() : eliminarLoading()}
        {errorDataMsg ? <Alert message="Error al cargar los datos" type="error" showIcon /> : null}
        <Row>
          <h1>Reportes</h1>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Card>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginBottom: '10px' }}>
                  <Title level={4}>Movimientos</Title>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginBottom: '10px' }}>
                  <RangePicker
                    style={{ width: '100%' }}
                    format={dateFormat}
                    value={rangoFechaMoment}
                    onChange={changeDateRange}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <AutoComplete
                    options={options}
                    onClick={onInit}
                    onSelect={(val, option) => onSelect(val, option)}
                    onSearch={onSearch}
                    onBlur={(val) => {
                      buscarSocioOnFocus(val.target.value.trim());
                    }}
                    value={nombreSocio}
                  >
                    <Input.Search size="large" placeholder="Buscar usuario" />
                  </AutoComplete>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <AutoComplete
                    options={optionsElem}
                    onClick={onInitElem}
                    onSelect={(val, option) => onSelectElem(val, option)}
                    onSearch={onSearchElem}
                    value={nombreElemento}
                  >
                    <Input.Search size="large" placeholder="Buscar elemento" />
                  </AutoComplete>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>&nbsp;</Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Space style={{ float: 'right' }}>
                    <Button type="primary" onClick={() => generarDocMov()}>
                      <FileExcelOutlined />
                      {' '}
                      Descargar
                    </Button>
                    <Button onClick={() => cleanFilterMov()}>Limpiar</Button>
                  </Space>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={1} lg={1} xl={1}>
          &nbsp;
          </Col>
          <Col xs={24} sm={24} md={11} lg={11} xl={11}>
            <Card>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginBottom: '10px' }}>
                <Title level={4}>Inventario</Title>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Select
                  style={{ width: '100%' }}
                  value={idTipo}
                  onChange={changeTipo}
                  placeholder="Seleccione tipo"
                  options={listaTipo}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>&nbsp;</Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Space style={{ float: 'right' }}>
                  <Button type="primary" onClick={() => generarDocInv()}>
                    <FileExcelOutlined />
                    {' '}
                    Descargar
                  </Button>
                  <Button onClick={() => cleanFilterInv()}>Limpiar</Button>
                </Space>
              </Col>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Reportes;
