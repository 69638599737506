import React from 'react';
import { Card, Col, Row } from 'antd';
import { ver_web } from './version_web';
/* import { ver_api } from "./version_api"; */

const AboutUs = () => (
  <>
    <div className="home">
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <h1>Sobre el Sistema</h1>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card>
            <h3>
              <Version />
            </h3>
          </Card>
        </Col>
      </Row>
    </div>
  </>
);

export default AboutUs;

const Version = () => (
  <p>{`Versión Web: ${ver_web.version_web}`}</p>
);
