import React, { useRef, useState } from 'react';
import {
  Card, Input, Row, Col, Alert, Image, Carousel,
} from 'antd';
import { BarcodeOutlined, CheckCircleOutlined, InboxOutlined } from '@ant-design/icons';

import { authAxios } from '../../../utils/axios-instance';
import seleccionarBg from '../../../assets/images/golf-icon-002.svg';
import { AutoCompleteWithKeyboard } from '../../InputWithKeyboard';

export default function Seleccionar() {
  const [elementos, setElementos] = useState();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const search = useRef(null);

  const onSelect = (value, option) => {
    const selectedElementos = option.elementos.filter(
      (elemento) => elemento.tipo_nombre !== 'casilla',
    );
    setElementos(selectedElementos);
    window.StepsData = JSON.stringify(selectedElementos.filter((elemento) => elemento.disponible));
    window.IdSocio = option.key.toString();
  };

  const onSearch = (searchText) => {
    setLoadingSearch(true);
    authAxios.get(
      `${process.env.REACT_APP_API_URL}/api/socios/`,
      {
        maxContentLength: Infinity,
        timeout: 60000,
        params: {
          search: searchText,
          offset: 0,
          limit: 10,
        },
      },
    ).then((response) => {
      setFilteredOptions(response.data.results.map((socio) => (
        {
          key: socio.id,
          value: `${socio.first_name} ${socio.last_name}`,
          label: (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {socio.first_name}
              {' '}
              {socio.last_name}
              <span style={{ color: '#6d6d6d' }}>
                <i><b>{socio.rut}</b></i>
              </span>
            </div>
          ),
          elementos: socio.elementos,
        }
      )));
      setLoadingSearch(false);
    }).catch(() => {
      setLoadingSearch(false);
    });
  };

  return (
    <>
      <AutoCompleteWithKeyboard
        options={filteredOptions}
        onSelect={onSelect}
        onSearch={onSearch}
      >
        <Input.Search
          size="large"
          placeholder="Buscar usuario"
          loading={loadingSearch}
          ref={search}
        />
      </AutoCompleteWithKeyboard>

      <div className="bg-image buscar-usuario">
        <Image
          style={{ objectFit: 'cover' }}
          src={seleccionarBg}
          preview={false}
        />
      </div>
      <div className="lista_elem">
        <ElementosCard elementos={elementos} />
      </div>
    </>
  );
}

function ElementosCard({ elementos }) {
  if (elementos) {
    if (elementos.length > 0) {
      const listCards = elementos.map((item) => (
        <div key={item.id}>
          <Card justify="center" key={item.id}>
            <Row>
              <Col span={24}>
                {item.disponible === true && (
                <Alert
                  message="Disponible para Retiro"
                  type="success"
                  showIcon
                />
                )}
                {item.disponible === false && (
                <Alert
                  message="En uso"
                  type="error"
                  showIcon
                />
                )}

                <>
                  <br />
                  <p>
                    <CheckCircleOutlined />
                    {` ${item.tipo_nombre.toUpperCase()}`}
                  </p>
                  <p>
                    <BarcodeOutlined />
                    {` CÓDIGO: ${item.codigo}`}
                  </p>
                  <p>
                    {item.contenedor_codigo !== null && (
                    <>
                      <InboxOutlined />
                      {` CASILLA: ${item.contenedor_codigo}`}
                    </>
                    )}
                  </p>
                </>
              </Col>
            </Row>
          </Card>
        </div>
      ));

      return (
        <>
          <Carousel dotPosition="top">
            {listCards}
          </Carousel>
        </>
      );
    }
    return (
      <>
        <Card justify="center" key="0">
          <p className="warning">
            <CheckCircleOutlined />
                            &nbsp;NO HAY ELEMENTOS
          </p>
        </Card>
      </>
    );
  }
  return (
    <>

    </>
  );
}
