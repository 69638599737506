import React, { useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Form } from 'antd';
import { BottomKeyboard } from './InputWithKeyboard';

export default function FormWithKeyboard({
  inputIds, enableKeyboard, children, ...props
}) {
  const [inputId, setInputId] = useState(inputIds[0]);
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [inputs, setInputs] = useState({});
  const keyboard = useRef(null);
  const [form] = Form.useForm();

  const onValuesChange = (changedValues, allValues) => {
    setInputs(allValues);
  };

  const onChangeAll = (newInputs) => {
    setInputs(newInputs);
    form.setFieldsValue(newInputs);
  };

  const onFocus = (event) => {
    setInputId(event.target.id);
    setShowKeyboard(true);
  };

  return (
    <>
      <Form
        initialValues={inputs}
        onFocus={onFocus}
        onValuesChange={onValuesChange}
        form={form}
        {...props}
      >
        {children}
      </Form>

      {isMobile && enableKeyboard && showKeyboard && (
        <BottomKeyboard
          keyboardRef={(ref) => {
            keyboard.current = ref;
            keyboard.current.replaceInput(inputs);
          }}
          inputName={inputId}
          onClickAway={() => {
            if (!inputIds.includes(document.activeElement.id)) {
              setShowKeyboard(false);
            }
          }}
          onChangeAll={onChangeAll}
        />
      )}
    </>
  );
}
