import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

const QRCodeImg = styled.img`
  width: 65px;
  height: 65px;
  margin-bottom: 6px;
`;

export default function GenerateQr({ datos }) {
  const abrevTipoElemento = ['', 'CA', 'BO', 'CM', 'CE', 'BA'];

  const imprimirCodigoQR = () => {
    const a = window.open('', '', 'height=500, width=800');
    const firstName = datos.first_name || '';
    const lastName = datos.last_name || '';
    const nombreSocio = firstName ? `${firstName} ${lastName}` : '';
    const elementoHeight = datos.tipo === 1 ? 115 : 130;
    a.document.write('<html lang="en">');
    a.document.write('<style>');
    a.document.write('@media print { body {width:100%; margin: 0px;} table{ margin: 0px; width: 100%;} }');
    a.document.write('</style>');
    a.document.write('<body>');
    a.document.write(
      '<table style="width: 100%; margin-bottom: 150px;">'
      + '<tr>'
      + `<td style="width: 33.3%; height: ${elementoHeight}px; border: 1px solid black; padding-left: 3px;">`
      + '<table>'
      + '<tr>'
      + '<td style="width: 60%; font-size: 24px;">'
      + `${abrevTipoElemento[datos.tipo]}:${datos.codigo}`
      + `<br>${abrevTipoElemento[datos.tipo]}${datos.id}`
      + '</td>'
      + '<td style="width: 40%;">'
      + `<img src="${datos.qr_url}" alt="qr-${datos.codigo}" height="100px" />`
      + '</td>'
      + '</tr>'
      + '<td style="width: 100%; height=21px; font-size: 16px;" colspan="2">'
      + `${datos.tipo !== 1 ? nombreSocio : ''}`
      + '</td>'
      + '</tr>'
      + '</table>'
      + '</td>'
      + `<td style="width: 33.3%; height: ${elementoHeight}px; padding-left: 3px;">&nbsp;</td>`
      + `<td style="width: 33.3%; height: ${elementoHeight}px; padding-left: 3px;">&nbsp;</td>`
      + '</tr>'
      + '</table>',
    );
    a.document.write('</body></html>');
    setTimeout(() => {
      a.document.close();
      a.print();
    }, 250);
  };

  return (
    <div className="GenerateQr">
      <QRCodeImg
        id={`qr-gen-${datos.id}`}
        src={datos.qr_url}
      />
      <p>
        <Button type="button" onClick={imprimirCodigoQR}>
          Imprimir código
        </Button>
      </p>
    </div>
  );
}
