import React, { useState } from 'react';
import {
  Steps, Button, message, Row, Col,
} from 'antd';
import Seleccionar from './stepsentregar/Seleccionar';
import Escanear from './stepsentregar/Escanear';
import { setMovimientos } from '../../hooks/ApiData';

const { Step } = Steps;

window.StepsData = '[]';
window.IdSocio = '';

const steps = [
  {
    title: 'Seleccionar',
    content: <Seleccionar />,
    titleblock: 'Seleccionar',
  },
  {
    title: 'Escanear y llenar datos',
    content: <Escanear />,
    titleblock: 'Escanear y llenar datos',
  },
];

const StepsEntregar = () => {
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <>
      <Row type="flex" justify="center">
        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
          <Steps direction="vertical" current={current}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </Col>
        <Col xs={24} sm={16} md={16} lg={16} xl={16}>
          <div className="ant-steps-item-title-block">{steps[current].titleblock}</div>
          <div className="steps-content">
            {steps[current].content}
            <br />
            <div className="steps-action">
              {current > 0 && (
                <Button className="ant-btn-primary" style={{ margin: '0 8px' }} onClick={() => prev()}>
                  Atrás
                </Button>
              )}
              {current < steps.length - 1 && (
                <Button type="primary" onClick={() => next()}>
                  Siguiente
                </Button>
              )}
              {current === steps.length - 1 && (

                <Button type="primary" onClick={() => ProcesoGuardar()}>
                  Aceptar
                </Button>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default StepsEntregar;

const ProcesoGuardar = () => {
  const elements = document.getElementsByClassName('idsDataEnd');
  let validate = true;
  let mensaje = '';
  const dataSave = [];
  /* const dataUpdate = []; */

  for (let i = 0; i < elements.length; i += 1) {
    let validateLine = true;
    let urlimg = '';
    let num_palos = '';
    if (elements[i].getAttribute('d-index') !== null) {
      const d_index = elements[i].getAttribute('d-index');
      if (document.getElementsByName(`checkScan_${d_index}`)[0].value === 'true') {
        if (document.getElementById(`checkTipo_${elements[i].value.toString()}`).value === '2') {
          if (document.getElementsByName(`checkNump_${d_index}`)[0].value === 'false') {
            validate = false;
            validateLine = false;
            mensaje = `Error Número Palos: ${document.getElementById(`checkCode_${elements[i].value.toString()}`).value}`;
            message.error(mensaje);
          } else {
            num_palos = document.getElementById(`numinput_${elements[i].value.toString()}`).value.toString();
          }
          if (document.getElementsByName(`checkFoto_${d_index}`)[0].value === 'false') {
            validate = false;
            validateLine = false;
            mensaje = `Error Foto: ${document.getElementById(`checkCode_${elements[i].value.toString()}`).value}`;
            message.error(mensaje);
          } else {
            urlimg = document.getElementById(`img_${elements[i].value.toString()}`).getElementsByClassName('ant-image-img')[0].getAttribute('src');
          }
        }

        if (validateLine === true) {
          const lineSave = {
            socio: window.IdSocio,
            elemento: elements[i].value.toString(),
            propiedades: null,
            code_el: document.getElementById(`checkCode_${elements[i].value.toString()}`).value,
            tipo_el: document.getElementById(`checkTipo_${elements[i].value.toString()}`).value,
            tipo: 'salida',
            num_palos,
            imgb64: urlimg,
          };
          dataSave.push(lineSave);
        }
      }
    }
  }

  if (validate === true && dataSave.length > 0) {
    const resp = setMovimientos(dataSave);
    if (resp === 'ok') {
      message.success('Proceso completo');
      setTimeout(() => { window.location.reload(); }, 2000);
      /* let resp2 = updateElementos(dataUpdate);
            if (resp2 === "ok"){
                message.success("Proceso completo");

                setTimeout(function() {window.location.reload();}.bind(this),2000);
            }else{
                message.error("Hubo un error en el sistema");
            } */
    } else {
      message.error('Hubo un error en el sistema');
    }
  } else {
    message.error('El proceso tuvo errores');
  }
};
